import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { modules } from '@app/consts/modules';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-goals-page',
  templateUrl: './employee-goals-page.component.html',
  styleUrls: ['./employee-goals-page.component.scss']
})
export class EmployeeGoalsPageComponent implements OnInit {
  navList = [
    {
        text: 'PersonalGoals',
        link: 'PersonalGoals',
        icon: 'badge'
    },
    {
      text: 'PublishedEmployeeGoals',
      link: `PublishedEmployeeGoals`,
      icon: 'domain'
    },
    {
      text: 'AllGoals',
      link: `AllGoals`,
      icon: 'domain'
    },
    {
      text: 'GoalPlans',
      link: `GoalPlans`,
      icon: 'domain'
    }
  ]
  public modules: typeof modules = modules;
  selectedSection: string = 'PersonalGoals';

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) { }
  

  ngOnInit(): void {
  }

  get employeeId(): string {
    let employeeId: string;
    
    this.route.paramMap.subscribe(
      params => employeeId = params.get("id")
    )
      
    return employeeId;
  }

}
