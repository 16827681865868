import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { RowActionButtonInput, RowActionEventOutput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, GridDataResult, RowClassArgs, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { Observable, finalize } from 'rxjs';

@Component({
  selector: 'app-local-data-grid-comp',
  templateUrl: './local-data-grid-comp.component.html',
  styleUrls: ['./local-data-grid-comp.component.scss']
})
export class LocalDataGridCompComponent implements OnInit {

  @Input() columns: any[];
  @Input() data: any;
  @Input() isLoading: boolean;
  @Input() pageSize: number = 20;
  @Input() skip: number;
  @Input() pageable: boolean = true;
  @Input() category: string;
  @Input() searchable: boolean;
  @Input() accessAndHistoryURL: string;
  @Input() parentModelId: string;
  @Input() tableId: string;
  @Input() export: string;
  @Input() clickableRows: boolean;
  @Input() hideActionButtons: boolean;
  @Input() actionButtonText: string;
  @Input() targetEmployeeTablePermissions: EmployeeTableSecurity[];
  @Input() rowActionButtons: RowActionButtonInput[];
  @Input() pageSettings = {
    info: true,
    pageSizes: [5, 10, 20, 50, 100],
    previousNext: true
  };


  @Input() rowSelectionSettings: {
    canSelectRows: boolean,
    selectRowBy: any,
    showDeleteAllButton?: boolean,
    selectableMode?: string,
  }
  = {
    canSelectRows: false,
    selectRowBy: 'id',
    showDeleteAllButton: true,
    selectableMode: 'multiple'
  }

  // @Input() canSelectRows: boolean;
  // @Input() selectRowBy: any = 'id';
  @Input() clearSelectedItems: string;

  @Input() defaultColumnTemplate: boolean = true;
  @Input() scrollable: any;
  @Input() rowHeight: any;
  @Input() height: any;

  @Input() customLoadingIndicator;

  @Input() sortableColumns: any[] = [];
  @Input() sortable: any;
  @Input() sort: any;

  @Input() filter: any;
  @Input() filterable: any;
  @Input() filterCategories: any[] = [];

  @Input() filterToggleDetails: {
    text: string,
    checked: boolean
  };

  @Output() emitPageChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitRowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitDeleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitSearchEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitFilterEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitSortEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitToggleFilter: EventEmitter<MatSlideToggleChange> = new EventEmitter();
  @Output() emitDataStateChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowActionEvent: EventEmitter<RowActionEventOutput> = new EventEmitter();
  @Output() emitSelectedRows: EventEmitter<SelectionEvent> = new EventEmitter();
  public selectedItems: any[] = [];
  searchValue: string;
  sortingColumns: boolean;
  fieldList: TableField[];

  filterToggle: boolean = false;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    private sanitiseGridDataService: SanitiseGridDataService
  ) {}

  ngOnInit(): void {
    if(this.tableId){
      this.getFields(this.tableId);
    }
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )
              let filterCategoriesIndex = this.filterCategories.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }
                else if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }

              if(filterCategoriesIndex !== -1){
                if(field.enabled === false){
                  this.filterCategories.splice(filterCategoriesIndex, 1);
                }
                else if(field.name !== null){
                  this.filterCategories[filterCategoriesIndex].title = field.name;
                }
              }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(this.tableId, this.targetEmployeeTablePermissions);

    tableFieldPermissions.forEach(
      tableFieldPermission => {
        let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )

        if(index !== -1){
          if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
            this.columns.splice(index, 1);
          }
        }
      }
    )
  }

  public isDotNotation(path) {
    return path.indexOf('.') !== -1;
  }

  cellClickHandler(args: CellClickEvent){
    this.emitRowClickEvent.emit(args.dataItem)
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public rowCallback = (context: RowClassArgs) => {
    if (this.clickableRows) {
      return { clickable: true };
    }
  };

  calculateSliderPercentage(value: string, max: number): number {
    return Number( ((parseFloat(value)/max) * (100/1)).toFixed() );
  }

  

}
