import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent extends SecurityProtectedBase implements OnInit {
  public routes: typeof routes = routes;
  navList = [
    {
        text: this.translate.instant('GoalTypes'),
        link: `${routes.PERFORMANCE}${routes.GOAL_TYPES}`,
        icon: 'adjust',
        featureCode: features.PERFORMANCE_GOAL_TYPES
    },
    {
      text: this.translate.instant('GoalPlans'),
      link: `${routes.PERFORMANCE}${routes.GOAL_PLANS}`,
      icon: 'fact_check',
      featureCode: [features.PERFORMANCE_GOAL_PLANS, features.PERFORMANCE_MANAGE_GOAL_PLANS]
    },
    {
      text: this.translate.instant('Goals'),
      link: `${routes.PERFORMANCE}${routes.GOALS}`,
      icon: 'adjust',
      featureCode: [features.PERFORMANCE_MY_GOALS, features.PERFORMANCE_DIRECT_REPORTEE_GOALS]
    },
    {
      text: this.translate.instant('ReviewPlans'),
      link: `${routes.PERFORMANCE}${routes.REVIEW_PLANS}`,
      icon: 'fact_check',
      featureCode: [features.PERFORMANCE_REVIEW_PLANS_ADMINISTRATION, features.PERFORMANCE_MANAGER_REVIEW_PLANS]
    },
    {
      text: this.translate.instant('ReviewRatings'),
      link: `${routes.PERFORMANCE}${routes.REVIEW_RATINGS}`,
      icon: 'fact_check',
      featureCode: features.PERFORMANCE_REVIEW_PLANS_ADMINISTRATION
    },
    {
      text: this.translate.instant('Reviews'),
      link: `${routes.PERFORMANCE}${routes.REVIEWS}`,
      icon: 'fact_check',
      featureCode: features.PERFORMANCE
    },
    {
      text: this.translate.instant('MyReviews'),
      link: `${routes.PERFORMANCE}${routes.MY_REVIEWS}`,
      icon: 'sentiment_very_satisfied',
      featureCode: features.PERFORMANCE
    },
  ];

  constructor(
      private translate: TranslateService,
      public router: Router,
      private featureServicePrivate: FeatureService,
      featureService: FeatureService,
  ) {
    super(featureService, features.PERFORMANCE)
  }

  ngOnInit(): void {
    if(this.hasFeatureAccess){
      this.navigateToFirstAllowedSection();
    }
  }

  navigateToFirstAllowedSection() {
    let urlToNavigateTo = '';

    if(this.router.url === `${routes.PERFORMANCE}`) {
      this.navList.every(element => {
        let hasAccess = this.featureServicePrivate.hasFeatureAccess(element.featureCode)
  
        if(hasAccess){
          urlToNavigateTo = element.link;
          return false;
        }

        return true;
      });
    }

    if(urlToNavigateTo !== ''){
      this.router.navigate([urlToNavigateTo]);
    }
}

}
