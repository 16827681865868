<h5 mat-dialog-title>{{ 'ReviewPlanReviewer-AddReviewer' | translate: {Default: "Add Reviewer"} }}</h5>
        
<mat-dialog-content>
    <ng-container *ngIf="loadingReviewPlanReviewer; else loaded">
        Loading
    </ng-container>
    <ng-template #loaded>
        <mat-stepper orientation="vertical" linear>
            <ng-template matStepperIcon="edit"><mat-icon class="material-icons-outlined">create</mat-icon></ng-template>
            <mat-step>
                <ng-template matStepLabel>{{ 'ReviewPlanReviewer-SelectReviewerType' | translate: {Default: "Select Reviewer Type"} }}</ng-template>
                <ng-template matStepContent>
                    <app-form-generator 
                        formId="frm_CfkszOWyvInRBj"
                        [formData]="selectReviewerTypeFormData"
                        [emitFormDataTrigger]="getSelectReviewerTypeFormData"
                        (emitFormData)="formDataEmitted($event, 'selectReviewerType')"
                        (emitFormPristine)="formPristineEmitted($event, 'selectReviewerType')"
                        (emitFormStatus)="formStatusUpdated($event, 'selectReviewerType')"
                        (emitFormDataChange)="emitFormDataChange($event, 'selectReviewerType')"
                    ></app-form-generator>
            
                    <div class="stepper-bottom">
                        <button 
                            mat-raised-button 
                            [disabled]="selectReviewerTypeFormValid === false" 
                            setColor="primaryColour" 
                            color="primary" 
                            matStepperNext
                        >{{ 'Stepper-Next' | translate: {Default: "Next"} }}</button>
                    </div>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'ReviewPlanReviewer-SelectReviewerSettings' | translate: {Default: "Select Reviewer Settings"} }}</ng-template>
                <ng-template matStepContent>
                    
                    <app-form-generator 
                        *ngIf="selectReviewerTypeFormData.reviewerTypeId === 'SpecifiedUser'"
                        formId="frm_S2wtVzO6TSbzKB"
                        [formData]="selectReviewerIdFormData"
                        [emitFormDataTrigger]="getSelectReviewerIdFormData"
                        (emitFormData)="formDataEmitted($event, 'selectReviewerId')"
                        (emitFormPristine)="formPristineEmitted($event, 'selectReviewerId')"
                        (emitFormStatus)="formStatusUpdated($event, 'selectReviewerId')"
                        (emitFormDataChange)="emitFormDataChange($event, 'selectReviewerId')"
                    ></app-form-generator>
    
                    <app-form-generator 
                        formId="frm_6zwIEbXyTEoxrL"
                        [formData]="selectReviewerSettingsFormData"
                        [emitFormDataTrigger]="getSelectReviewerSettingsFormData"
                        (emitFormData)="formDataEmitted($event, 'selectReviewerSettings')"
                        (emitFormPristine)="formPristineEmitted($event, 'selectReviewerSettings')"
                        (emitFormStatus)="formStatusUpdated($event, 'selectReviewerSettings')"
                        (emitFormDataChange)="emitFormDataChange($event, 'selectReviewerSettings')"
                    ></app-form-generator>
                    
                    <div class="stepper-bottom">
                        <button mat-raised-button matStepperPrevious>{{ 'Stepper-Back' | translate: {Default: "Back"} }}</button>
                        <button 
                            mat-raised-button 
                            [disabled]="formsInvalid" 
                            color="primary" 
                            [disabled]="selectReviewerSettingsFormValid === false" 
                            setColor="primaryColour"
                            matStepperNext
                        >{{ 'Stepper-Next' | translate: {Default: "Next"} }}</button>
                    </div>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'ReviewPlanReviewer-ReviewVisibility' | translate: {Default: "Review Visibility"} }}</ng-template>
                <ng-template matStepContent>
    
                    <ng-container *ngFor="let reviewSecurityItem of reviewerSecurity">
                        <!-- {{reviewSecurityItem | json}} -->
                        <div class="review-security-item">
                            <div class="top">
                                <div class="employee-name">
                                    <app-employee-image [employeeId]="reviewSecurityItem.reviewer?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
            
                                    <span class="employee">
                                        {{reviewSecurityItem.reviewer?.firstName}} {{reviewSecurityItem.reviewer?.lastName}}
                                    </span>
                                </div>
        
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Reviewer Type
                                    </mat-label>
                        
                                    <input disabled matInput type="text" [value]="reviewSecurityItem.reviewerType?.name">
                                </mat-form-field>
        
                                <!-- <mat-form-field appearance="fill">
                                    <mat-label>
                                        Overall Comments Security
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering [(value)]="reviewSecurityItem.overallCommentsSecurity" [compareWith]="compareById">
                                        <mat-option></mat-option>
                                        <mat-option *ngFor="let option of reviewerSecurityPermissions" [value]="option">{{option.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
        
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Overall Rating Security
                                    </mat-label>
        
                                    <mat-select disableOptionCentering [(value)]="reviewSecurityItem.overallRatingSecurity" [compareWith]="compareById">
                                        <mat-option></mat-option>
                                        <mat-option *ngFor="let option of reviewerSecurityPermissions" [value]="option">{{option.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                            </div>
                            <div class="bottom">
    
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        Security
                                      </mat-panel-title>
                                    </mat-expansion-panel-header>
                                
                                    <kendo-grid class="reviewer-security-grid" [data]="reviewSecurityItem.security">
                                        <kendo-grid-column field="reviewer" title="reviewer" 
                                            [headerClass]="{ 'text-center': true }"
                                            [class]="{ 'text-center': true }"
                                        >
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="employee-name">
                                                    <app-employee-image [employeeId]="dataItem.reviewer?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                            
                                                    <span class="employee">
                                                        {{dataItem.reviewer?.firstName}} {{dataItem.reviewer?.lastName}}
                                                    </span>
                                                </div>
                                            </ng-template>
                                        </kendo-grid-column>
            
                                        <kendo-grid-column field="reviewerType.name" title="Reviewer Type" 
                                            [headerClass]="{ 'text-center': true }"
                                            [class]="{ 'text-center': true }"
                                        >
                                        </kendo-grid-column>
            
                                        <kendo-grid-column field="viewComments" title="View Comments" 
                                            [headerClass]="{ 'text-center': true }"
                                            [class]="{ 'text-center': true }"
                                        >
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <mat-slide-toggle [(ngModel)]="dataItem.viewComments"></mat-slide-toggle>
                                            </ng-template>
                                        </kendo-grid-column>
            
                                        <kendo-grid-column field="viewRating" title="View Rating" 
                                            [headerClass]="{ 'text-center': true }"
                                            [class]="{ 'text-center': true }"
                                        >
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <mat-slide-toggle [(ngModel)]="dataItem.viewRating"></mat-slide-toggle>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </kendo-grid>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </ng-container>
            
                    <div class="stepper-bottom">
                        <button mat-raised-button matStepperPrevious>{{ 'Stepper-Back' | translate: {Default: "Back"} }}</button>
                        <button 
                            mat-raised-button 
                            setColor="primaryColour" 
                            color="primary" 
                            [disabled]="formsInvalid" 
                            appPreventDoubleClick 
                            (throttledClick)="save()">
                            {{ 'Save' | translate: {Default: "Save"} }}
                        </button> 
                    </div>
                </ng-template>
            </mat-step>
        </mat-stepper>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    
</mat-dialog-actions>

  