<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'ViewFutureSchedule'">{{ 'ViewFutureSchedule' | translate: {Default: "View Future Schedule"} }}</h5>

<mat-dialog-content>
    <div class="top">
        <h6 appLocalizationPopupDirective [localizationCode]="'TimeOffType'">{{ 'TimeOffType' | translate: {Default: "Time Off Type"} }}: {{employeeTimeOffType.timeOffType.name}}</h6>
    
        <form [formGroup]="dateRangeForm">
            <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'From'">
              <mat-label>{{ 'From' | translate: {Default: "From"} }}</mat-label>
              <input matInput [matDatepicker]="fromDatepicker" formControlName="from">
              <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #fromDatepicker></mat-datepicker>
            </mat-form-field>
      
            <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'To'">
              <mat-label>{{ 'To' | translate: {Default: "To"} }}</mat-label>
              <input matInput [matDatepicker]="toDatepicker" formControlName="to">
              <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatepicker></mat-datepicker>
            </mat-form-field>
        </form>
    </div>

    <app-local-data-grid-comp
        class="future-schedule-data-grid"
        [columns]="columns"
        [data]="timeOffCalculationLog"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [pageable]="false"
        category=""
        export="excel"
    ></app-local-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>