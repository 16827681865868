import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalTypeDialogComponent } from './components/goal-type-dialog/goal-type-dialog.component';
import { GoalType, GoalTypeVerbose } from './models/goal-type.model';
import { GoalTypeService } from './services/goal-type.service';

@Component({
  selector: 'app-goal-types',
  templateUrl: './goal-types.component.html',
  styleUrls: ['./goal-types.component.scss']
})
export class GoalTypesComponent implements OnInit {
  public columns: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name" },
    { field: "allowEmployeeGoalEntry", tableId: "tfi_PgTAllowEmployeeGoalEntry", title: "Allow Employee Goal Entry", type: "booleanToggle"},
    { field: "hasNotes", tableId: "tfi_PgTHasNotes", title: "Has Notes", type: "booleanToggle"},
    { field: "allowEmployeeSubGoalCreation", tableId: "tfi_PgTAllowEmployeeSubGoalCreation", title: "Allow Employee Sub Goal Creation", type: "booleanToggle"},
    { field: "completionDateFieldSetting", subField: "description", tableId: "tfi_PgTCompletionDateFieldSetting", title: "Completion Date Field Setting"},
    { field: "expectedCompletionDateFieldSetting", subField: "description", tableId: "tfi_PgTExpectedCompletionDateFieldSetting", title: "Expected Completion Date Field Setting"},
    // { field: "statusFieldSetting", subField: "description", tableId: "tfi_PgTStatusFieldSetting", title: "Status Field Setting"},
    { field: "percentageCompleteFieldSetting", subField: "description", tableId: "tfi_PgTPercentageCompleteFieldSetting", title: "Percentage Complete Field Setting"},
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name", type: "String", dataType: 'String'},
    { field: "completionDateFieldSetting", subField: "description", tableId: "tfi_PgTCompletionDateFieldSetting", title: "Completion Date Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "expectedCompletionDateFieldSetting", subField: "description", tableId: "tfi_PgTExpectedCompletionDateFieldSetting", title: "Expected Completion Date Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    // { field: "statusFieldSetting", subField: "description", tableId: "tfi_PgTStatusFieldSetting", title: "Status Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "percentageCompleteFieldSettingDescription", subField: "description", tableId: "tfi_PgTPercentageCompleteFieldSetting", title: "Percentage Complete Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "allowEmployeeSubGoalCreation", tableId: "tfi_PgTAllowEmployeeSubGoalCreation", title: "Allow Employee Sub Goal Creation", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "hasNotes", tableId: "tfi_PgTHasNotes", title: "Has Notes", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "allowEmployeeGoalEntry", tableId: "tfi_PgTAllowEmployeeGoalEntry", title: "Allow Employee Goal Entry", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "allowEmployeeGoalEntry", sortValue: "allowEmployeeGoalEntry"},
    { field: "allowEmployeeSubGoalCreation", sortValue: "allowEmployeeSubGoalCreation"},
    { field: "hasNotes", sortValue: "hasNotes"},
    { field: "completionDateFieldSetting", sortValue: "completionDateFieldSettingDescription"},
    { field: "expectedCompletionDateFieldSetting", sortValue: "expectedCompletionDateFieldSettingDescription"},
    // { field: "statusFieldSetting", sortValue: "statusFieldSettingDescription"},
    { field: "percentageCompleteFieldSetting", sortValue: "percentageCompleteFieldSettingDescription"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_U2ORn3HXxnaSp9'
  changeReasonFormId: string = 'frm_jxyqqwbOQmJ4NB';
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private goalTypeService: GoalTypeService
  ) {
  }

  ngOnInit(): void {
    this.view = this.getGoalTypes();
  }

  getGoalTypes() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getGoalsTypesRequest = this.goalTypeService.getGoalTypes(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getGoalType(goalType?: GoalType) {
    if(goalType === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;
      this.goalTypeService.getGoalType(goalType.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(goalType?: GoalTypeVerbose) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goalType: goalType
    };

    this.dialogRef = this.dialog.open(GoalTypeDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGoalTypes();
          // this.delete(itemToDelete.id);
        }
        else if(data) {
          this.getGoalTypes();

          this.isLoading = true;
          this.goalTypeService.getGoalType(data)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe(
            res => {
              this.openFormDialog(res);
            }
          );
        }
      }
    );
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(formData.id === null){
      this.goalTypeService.createGoalType(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getGoalTypes();
        }
      );
    }
    else {
      this.goalTypeService.updateGoalType(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getGoalTypes();
        }
      );
    }
  }

  deleteGoalTypes(goalTypeIds: string[]) {
    const observables = goalTypeIds.map(selectedItem => defer(() => this.goalTypeService.deleteGoalType(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGoalTypes();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoalTypes();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getGoalsTypesRequest.unsubscribe();
    this.getGoalTypes();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoalTypes();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoalTypes();
  }
}
