import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { DropAction, TreeItemDropEvent, TreeItemLookup } from '@progress/kendo-angular-treeview';
import * as moment from 'moment';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalType, GoalTypeRating, GoalTypeRatingSubmit, GoalTypeRatingVerbose, GoalTypeSubmit } from '../../models/goal-type.model';
import { GoalTypeService } from '../../services/goal-type.service';
import { Localization } from '@app/shared/models/localization.model';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-goal-type-dialog',
  templateUrl: './goal-type-dialog.component.html',
  styleUrls: ['./goal-type-dialog.component.scss']
})
export class GoalTypeDialogComponent implements OnInit {
  formId: string = 'frm_U2ORn3HXxnaSp9';
  goalTypeRatingFromId: string = 'frm_6qffu7qNfxFiTG';
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;
  goalType: GoalType;
  isLoadingRatings: boolean;
  goalTypeRatings: GoalTypeRating[];
  isLoadingRating: boolean;
  formGeneratorDialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  goalTypeRatingsVerbose: GoalTypeRatingVerbose[] = [];
  goalTypeRatingsToDelete: GoalTypeRatingVerbose[] = [];
  currentEmployeeDetails: CurrentUserDetails;
  formPristine: boolean = true;
  ratingsPristine: boolean = true;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public router: Router,
    private goalTypeService: GoalTypeService,
    private dialogRef: MatDialogRef<GoalTypeDialogComponent>,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.goalType = data.goalType;
  }

  ngOnInit(): void {
    this.buildFormData();
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()

    if(this.goalType){
      this.getGoalTypeRatings(this.goalType.id);
    }
  }

  getGoalTypeRatings(goalTypeId: string){
    this.isLoadingRatings = true;
    let filterString = `(goalTypeId = "${goalTypeId}")`;

    this.goalTypeService.getGoalTypeRatings(goalTypeId, '1000', 0, null)
    .pipe(
      finalize( () => {
        this.isLoadingRatings = false;
      })
    )
    .subscribe(
      (res) => {
        this.goalTypeRatings = res.data;
        this.getAllGoalTypeRatingsVerbose();
      }
    );
  }

  buildFormData(){
    this.formData = {
      id: this.goalType ? this.goalType.id : null,
      name: this.goalType ? this.goalType.name : null,
      allowEmployeeGoalEntry: this.goalType ? this.goalType.allowEmployeeGoalEntry : false,
      allowEmployeeSubGoalCreation: this.goalType ? this.goalType.allowEmployeeSubGoalCreation : false,
      completionDateFieldSetting: this.goalType ? this.goalType.completionDateFieldSetting?.id : null,
      expectedCompletionDateFieldSetting: this.goalType ? this.goalType.expectedCompletionDateFieldSetting?.id : null,
      statusFieldSetting: this.goalType ? this.goalType.statusFieldSetting?.id : null,
      percentageCompleteFieldSetting: this.goalType ? this.goalType.percentageCompleteFieldSetting?.id : null,
      hasNotes: this.goalType ? this.goalType.hasNotes : false,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: null,
    }
  }

  formDataEmitted(formDataEmitted) {
    this.save(formDataEmitted);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  handleDrop(event: TreeItemDropEvent): void {
    const fromIndex = this.goalTypeRatingsVerbose.indexOf(event?.sourceItem?.item?.dataItem);
    const toIndex = event.dropPosition - 1;
    const element = this.goalTypeRatingsVerbose.splice(fromIndex, 1)[0];

    this.goalTypeRatingsVerbose.splice(toIndex, 0, element);

    this.goalTypeRatingsVerbose.forEach((goalTypeRating, index) => {
      goalTypeRating.sortOrder = index+1;
    });
  }

  public iconClass({ text }: any): any {
    return {
      "k-icon": true,
    };
  }

  public getDragStatus(
    action: DropAction,
    destinationItem: TreeItemLookup
  ): string {
    if (
      destinationItem &&
      action === DropAction.Add
    ) {
      return "k-i-cancel";
    }

    switch (action) {
      case DropAction.Add:
        return "k-i-plus";
      case DropAction.InsertTop:
        return "k-i-insert-top";
      case DropAction.InsertBottom:
        return "k-i-insert-bottom";
      case DropAction.InsertMiddle:
        return "k-i-insert-middle";
      case DropAction.Invalid:
      default:
        return "k-i-cancel";
    }
  }

  getAllGoalTypeRatingsVerbose() {
    const observables = this.goalTypeRatings.map(goalTypeRating => defer(() => this.goalTypeService.getGoalTypeRating(this.goalType.id, goalTypeRating.id)));

    this.isLoadingRatings = true;
    this.goalTypeRatingsVerbose = [];

    forkJoin(observables)
      .pipe(
          finalize(() => this.isLoadingRatings = false)
      )
      .subscribe(
          (res) => {
            res.sort( (a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
              if (a.sortOrder > b.sortOrder) {
                return 1;
              }

              // sortOrder must be equal
              return 0;
            });

            res.forEach((goalTypeRating, index) => {
              goalTypeRating.sortOrder = index+1;
            });

            this.goalTypeRatingsVerbose = res;
          }
      );
  }

  getCurrentCultureRatingName(goalTypeRating?: GoalTypeRatingVerbose) {
    const result: Localization[] = goalTypeRating?.name?.filter(n => n.culture === this.currentEmployeeDetails.culture.id);

    return result !== undefined ? result[0]?.text : null;
  }

  openRatingDialog(goalTypeRating?: GoalTypeRatingVerbose) {
    let formData = {
        id: goalTypeRating ? goalTypeRating.id : null,
        asOf: moment().format(),
        changeReason: '',
        changeReasonComments: '',
        name: goalTypeRating ? goalTypeRating.name : null,
        sortOrder: goalTypeRating ? goalTypeRating.sortOrder : null,
        ratingNumber: goalTypeRating ? goalTypeRating.ratingNumber : null,
        goalTypeId: goalTypeRating ? goalTypeRating.goalTypeId : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        formTitle: `${this.translate.instant('GoalType')}`,
        formId: this.goalTypeRatingFromId,
        formData: formData
    };

    this.formGeneratorDialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.formGeneratorDialogRef.componentInstance.emitFormData.subscribe((event:{name: Localization[], ratingNumber: number}) => {
      let index = this.goalTypeRatingsVerbose?.indexOf(goalTypeRating);
      let request;

      const goalTypeRatingSubmit: GoalTypeRatingSubmit = {
        name: event.name,
        sortOrder: goalTypeRating ? goalTypeRating.sortOrder : this.goalTypeRatingsVerbose.length + 1,
        ratingNumber: event.ratingNumber,
        goalTypeId: this.goalType.id,
        originalCreatedOn: goalTypeRating ? goalTypeRating.version.versionCreatedOn : moment().format(),
        asOf: moment().format(),
        changeReason: '',
        changeReasonComments: ''
      }

      if(index > -1){
        request = this.goalTypeService.updateGoalTypeRating(this.goalType.id, goalTypeRating.id, goalTypeRatingSubmit)
      }
      else {
        request = this.goalTypeService.createGoalTypeRating(this.goalType.id, goalTypeRatingSubmit)
      }

      request
      .pipe(
        finalize(() => {
          this.formGeneratorDialogRef.close();
          this.getGoalTypeRatings(this.goalType.id);
        })
      )
      .subscribe(
          (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          }
      );

      // // If you are editting a rating replace it with the new rating information
      // if(index !== -1 && index !== undefined){
      //   this.goalTypeRatingsVerbose[index] = {
      //     id: goalTypeRating ? goalTypeRating.id : null,
      //     name: event.name, //event.name TODO; fix,
      //     sortOrder: goalTypeRating ? goalTypeRating.sortOrder : null,
      //     goalTypeId: goalTypeRating ? goalTypeRating.goalTypeId : null,
      //     version: goalTypeRating ? goalTypeRating.version : null
      //   }
      // }
      // // If you are creating a new rating add it to the list
      // else {
      //   this.goalTypeRatingsVerbose.push(
      //     {
      //       id: goalTypeRating ? goalTypeRating.id : null,
      //       name: event.name, //event.name TODO; fix,
      //       sortOrder: this.goalTypeRatingsVerbose.length + 1,
      //       goalTypeId: goalTypeRating ? goalTypeRating.goalTypeId : null,
      //       version: goalTypeRating ? goalTypeRating.version : null
      //     }
      //   )
      // }

      // this.ratingsPristine = false;

      
    });
  }

  openConfirmDeleteDialog(goalTypeRating: GoalTypeRatingVerbose) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `Are you sure you want to delete this rating?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteRating(goalTypeRating);
        }
      }
    ); 
  }

  deleteRating(goalTypeRating: GoalTypeRatingVerbose){
    this.overlayService.show();

    this.goalTypeService.deleteGoalTypeRating(this.goalType.id, goalTypeRating.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
        this.getGoalTypeRatings(this.goalType.id);
      }
    );
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.dialogRef.close();
        }
      }
    );
  }

  close(){
    (this.formPristine && this.ratingsPristine) ? this.dialogRef.close() : this.openConfirmCloseDialog();
  }

  save(formData: GoalTypeSubmit){
    this.overlayService.show();

    if(this.goalType) {
      this.goalTypeService.updateGoalType(formData)
      .pipe(
          finalize(() => this.overlayService.hide())
      )
      .subscribe(
          (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            this.saveRatings();
          }
      );
    }
    else {
      this.goalTypeService.createGoalType(formData)
      .pipe(
          finalize(() => this.overlayService.hide())
      )
      .subscribe(
          (res) => {
            this.goalType = {
              id: res.goalTypeId,
              name: null,
              hasNotes: false,
              allowEmployeeGoalEntry: false,
              allowEmployeeSubGoalCreation: false,
              completionDateFieldSetting: null,
              expectedCompletionDateFieldSetting: null,
              statusFieldSetting: null,
              percentageCompleteFieldSetting: null,
            };

            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(res.goalTypeId);
          }
      );
    }
  }

  saveRatings() {
    let observables: Observable<any>[] = [];

    this.goalTypeRatingsVerbose.forEach(
      goalTypeRating => {
        const goalTypeRatingSubmit: GoalTypeRatingSubmit = {
          name: goalTypeRating.name.filter(localization => localization.text !== ""),
          sortOrder: goalTypeRating.sortOrder,
          ratingNumber: goalTypeRating.ratingNumber,
          goalTypeId: this.goalType.id,
          originalCreatedOn: moment().format(),
          asOf: moment().format(),
          changeReason: '',
          changeReasonComments: ''
        }

        if(goalTypeRating.id !== undefined && goalTypeRating.id !== null){
          observables.push(this.goalTypeService.updateGoalTypeRating(this.goalType.id, goalTypeRating.id, goalTypeRatingSubmit))
        }
        else {
          observables.push(this.goalTypeService.createGoalTypeRating(this.goalType.id, goalTypeRatingSubmit))
        }
      }
    );

    // this.goalTypeRatingsToDelete.forEach(
    //   goalTypeRating => {
    //     if(goalTypeRating.id !== undefined && goalTypeRating.id !== null){
    //       observables.push(this.goalTypeService.deleteGoalTypeRating(this.goalType.id, goalTypeRating.id))
    //     }
    //   }
    // );

    if(observables.length === 0){
      this.dialogRef.close(true);
    }
    else {
      forkJoin(observables)
      .subscribe(
          (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(true);
          }
      );
    }

  }

}
