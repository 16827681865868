import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeAssignedGoalPlan, EmployeeGoal, EmployeeGoalSubmit, EmployeeGoalsWithEmployeeId, GoalComment, GoalCommentSubmit, GoalPlan, GoalPlanEmployee, GoalPlanOwner, GoalPlanPublishHistory, GoalPlanSubmit, GoalPlanVerbose, GoalSubmit, GoalVerbose, SubmitGoalPlanOwner } from '../models/goal-plan.model';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class GoalPlanService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCurrentUserGoalPlans(): Observable<{id: string, name: string}> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${api_routes.CURRENT_USER}`, {
      headers: headers
    });
  }

  getEmployeeGoalPlans(employeeId: string, goalEntryAllowed: boolean): Observable<EmployeeAssignedGoalPlan[]> {
    let params = new HttpParams();
    params = params.append('goalEntryAllowed', goalEntryAllowed);
    
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${api_routes.EMPLOYEE}/${employeeId}`, {
      headers: headers,
      params: params
    });
  }

  getGoalPlans(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalPlan>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}`, {
      headers: headers,
      params: params
    });
  }

  getGoalPlan(goalPlanId: string): Observable<GoalPlanVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}`, {
      headers: headers
    });
  }

  createGoalPlan(goalPlan: GoalPlanSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}`, goalPlan, {
      headers: headers
    });
  }

  updateGoalPlan(goalPlan: GoalPlanSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlan.id}`, goalPlan, {
      headers: headers
    });
  }

  deleteGoalPlan(goalPlanId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}`, {
      headers: headers
    });
  }

  getGoalPlanEmployees(goalPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalPlanEmployee>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.EMPLOYEES}`, {
      headers: headers,
      params: params
    });
  }

  addGoalPlanEmployee(goalPlanId: string, employeeId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.EMPLOYEES}/${employeeId}`, {
      headers: headers
    });
  }

  deleteGoalPlanEmployee(goalPlanId: string, employeeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.EMPLOYEES}/${employeeId}`, {
      headers: headers
    });
  }

  getGoals(goalPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalPlan>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.GOALS}`, {
      headers: headers,
      params: params
    });
  }

  getGoal(goalPlanId: string, goalId: string): Observable<GoalVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.GOALS}/${goalId}`, {
      headers: headers
    });
  }

  createGoal(goalPlanId: string, goal: GoalSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.GOALS}`, goal, {
      headers: headers
    });
  }

  updateGoal(goalPlanId: string, goal: GoalSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.GOALS}/${goal.id}`, goal, {
      headers: headers
    });
  }

  deleteGoal(goalPlanId: string, goalId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.GOALS}/${goalId}`, {
      headers: headers
    });
  }

  publishGoalPlan(goalPlanId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${api_routes.PUBLISH}/${goalPlanId}`, {
      headers: headers
    });
  }

  getEmployeeGoals(employeeId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<EmployeeGoalsWithEmployeeId> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      map(res => {
        return {
          employeeId: employeeId,
          employeeGoals: res
        }
      })
    );

    // return of(
    //     {
    //       employeeId: employeeId,
    //       employeeGoals: {
    //         data: [
    //           {"id":"gol_PU1327sgjf9sOD","description":"sdagasdfads","percentageComplete":0.2400,"expectedCompletionDate":"2022-12-01T00:00:00","actualCompletionDate":"2022-12-29T00:00:00","goalType":{"id":"pgt_4fdMmjRwaeY1yp","name":"testing fin"}}
    //         ],
    //         "skip": 0,
    //         "take": 50,
    //         "totalPages": 1,
    //         "total": 1
    //       }
    //     }
    // );

  }

  getEmployeeGoal(employeeId: string, goalId: string): Observable<EmployeeGoal> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}`, {
      headers: headers
    });
  }

  createEmployeeGoal(employeeId: string, goal: EmployeeGoalSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}`, goal, {
      headers: headers
    });
  }

  updateEmployeeGoal(employeeId: string, goal: EmployeeGoalSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goal.id}`, goal, {
      headers: headers
    });
  }

  deleteEmployeeGoal(employeeId: string, goalId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}`, {
      headers: headers
    });
  }

  getGoalComments(employeeId: string, goalId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}/${api_routes.COMMENTS}`, {
      headers: headers,
      params: params
    });

    // return of(
    //   {
    //     data: [
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             "id":"usr_f8GHeWSZZWGVkH",
    //             "firstName":"=Test Field Sec",
    //             "lastName":"(DONT CHANGE)"
    //           }
    //         }
    //       },
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             firstName: "(: DO NOT CHANGE",
    //             id: "usr_Q58GDL7caiRl0A",
    //             lastName: "(: Assistants"
    //           }
    //         }
    //       },
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             firstName: "(: DO NOT CHANGE",
    //             id: "usr_Q58GDL7caiRl0A",
    //             lastName: "(: Assistants"
    //           }
    //         }
    //       },
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             firstName: "(: DO NOT CHANGE",
    //             id: "usr_Q58GDL7caiRl0A",
    //             lastName: "(: Assistants"
    //           }
    //         }
    //       },
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             firstName: "(: DO NOT CHANGE",
    //             id: "usr_Q58GDL7caiRl0A",
    //             lastName: "(: Assistants"
    //           }
    //         }
    //       },
    //       {
    //         id: "string",
    //         comments: "Great Job",
    //         commentedOn: "2022-12-06T23:42:23.4593976+00:00",
    //         version: {
    //           "createdOn":"2022-12-06T23:42:23.4593976+00:00",
    //           "effectiveDate":"2022-12-06T00:00:00",
    //           "modifiedList":null,
    //           "changeReason":null,
    //           "comments":null,
    //           "isDeleted":false,
    //           "createdBy":{
    //             firstName: "(: DO NOT CHANGE",
    //             id: "usr_Q58GDL7caiRl0A",
    //             lastName: "(: Assistants"
    //           }
    //         }
    //       },
    //     ],
    //     "skip": 0,
    //     "take": 50,
    //     "totalPages": 1,
    //     "total": 1
    //   }
    // )
  }

  getGoalComment(employeeId: string, goalId: string, commentId: string): Observable<GoalComment> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}/${api_routes.COMMENTS}/${commentId}`, {
      headers: headers
    });
  }

  createComment(employeeId: string, goalId: string, commentSubmit: GoalCommentSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}/${api_routes.COMMENTS}`, commentSubmit, {
      headers: headers
    });
  }

  updateComment(employeeId: string, goalId: string, commentId: string, commentSubmit: GoalCommentSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}/${api_routes.COMMENTS}/${commentId}`, commentSubmit, {
      headers: headers
    });
  }

  deleteComment(employeeId: string, goalId: string, commentId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.EMPLOYEES}/${employeeId}/${api_routes.GOALS}/${goalId}/${api_routes.COMMENTS}/${commentId}`, {
      headers: headers
    });
  }

  getDirectReporteesGoals(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<any> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOALS}/${api_routes.DIRECT_REPORTEES}`, {
      headers: headers,
      params: params
    });

    return of(
      {
        "data":[
           {
              "id":"gol_3YREZE0U1WdmCj",
              "description":"test new goal",
              "percentageComplete":0.1800,
              "expectedCompletionDate":"2023-05-31T00:00:00",
              "actualCompletionDate":null,
              "goalType":{
                 "id":"pgt_aliwQ2vOEwPP0t",
                 "name":"new test"
              },
              "goalPlan":{
                "id":"pgt_aliwQ2vOEwPP0t",
                "name":"new test"
              },
              "employee": {
                "id": "usr_f8GHeWSZZWGVkH",
                "firstName": "=Test Field Sec",
                "lastName": "(DONT CHANGE)"
              },
              "createdBy": {
                "id": "usr_f8GHeWSZZWGVkH",
                "firstName": "=Test Field Sec",
                "lastName": "(DONT CHANGE)"
              },
           }
        ],
        "skip":0,
        "take":5,
        "total":1
     }
    )
  }

  getAllGoals(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<any> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOALS}`, {
      headers: headers,
      params: params
    });

    return of(
      {
        "data":[
           {
              "id":"gol_3YREZE0U1WdmCj",
              "description":"test new goal",
              "percentageComplete":0.1800,
              "expectedCompletionDate":"2023-05-31T00:00:00",
              "actualCompletionDate":null,
              "goalType":{
                 "id":"pgt_aliwQ2vOEwPP0t",
                 "name":"new test"
              },
              "goalPlan":{
                "id":"pgt_aliwQ2vOEwPP0t",
                "name":"new test"
              },
              "employee": {
                "id": "usr_f8GHeWSZZWGVkH",
                "firstName": "=Test Field Sec",
                "lastName": "(DONT CHANGE)"
              },
              "createdBy": {
                "id": "usr_f8GHeWSZZWGVkH",
                "firstName": "=Test Field Sec",
                "lastName": "(DONT CHANGE)"
              },
              "managers": [
                {
                "id": "usr_f8GHeWSZZWGVkH",
                "firstName": "=Test Field Sec",
                "lastName": "(DONT CHANGE)"
                },
                {
                  "id": "usr_f8GHeWSZZWGVkH",
                  "firstName": "=Test Field Sec",
                  "lastName": "(DONT CHANGE)"
                },
              ],
           }
        ],
        "skip":0,
        "take":5,
        "total":1
     }
    )
  }
  
  getGoalPlanPublishHistory(goalPlanId: string): Observable<PagedData<GoalPlanPublishHistory>> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}/${goalPlanId}/${api_routes.PUBLISH_HISTORIES}`, {
      headers: headers
    });
  }

  getGoalPlanOwners(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalPlanOwner>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLAN_OWNERS}`, {
      headers: headers,
      params: params
    });
  }

  addGoalPlanOwner(submitGoalPlanOwner: SubmitGoalPlanOwner): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLAN_OWNERS}`, submitGoalPlanOwner, {
      headers: headers
    });
  }

  deleteGoalPlanOwner(goalPlanOwnerId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLAN_OWNERS}/${goalPlanOwnerId}`, {
      headers: headers
    });
  }
}

