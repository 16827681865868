import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Holiday, HolidayGroupSubmit, HolidayGroupVerbose, HolidaySubmit, HolidayVerbose } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import { defer, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';

@Component({
    selector: 'app-work-rotation-editor',
    templateUrl: './holiday-groups-editor.component.html',
    styleUrls: ['./holiday-groups-editor.component.scss']
})
export class HolidayGroupsEditorComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent;

    public holidayGroupId: string;
    public isLoading: boolean;
    public holidayGroup: HolidayGroupVerbose = null;
    public gridDataResult: GridDataResult = {
        data: [],
        total: 0,
    };
    public pageSize: number = 20;
    public skip: number = 0;

    public columns: any[] = [
        { field: "name", title: "Name", type: "navigation"},
        { field: "startDate", title: "Start Date", type: "date"},
        { field: "endDate", title: "End Date", type: "date"},
        { field: "hourOverride", title: "Hours Override"},
        { field: "notes", title: "Notes"},
    ];
    formId: string = 'frm_0bC3Q4XDVP2UZS';
    getFormData: boolean = false;
    formValid: boolean = false;
    formData: any;
    dialogRef: any;
    isLoadingHoliday: boolean;
    clearSelectedItems: boolean = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private translate: TranslateService,
        private holidayService: StatutoryHolidaysService,
    ) {
    }

    ngOnInit(): void {
        this.holidayGroupId = this.getIdInURL();

        if(this.holidayGroupId !== null){
            this.getHolidayGroup();
        }
        else {
            this.createFormData();
            this.isLoading = false;
        }
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('holidayGroupId')
        );

        return IdInURL;
    }

    formDataEmitted(formData) {
        // this.openChangeReasonDialog(formData);
        this.saveHolidayGroup(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    getHolidayGroup() {
        this.isLoading = true;
        this.holidayService.getHolidayGroup(this.holidayGroupId)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                res => {
                    this.holidayGroup = res;
                    this.getHolidayDays();
                }
            );
    }

    getHolidayDays() {
        this.isLoadingHoliday = true;

        this.holidayService.getHolidayDays(this.holidayGroupId, this.skip, String(this.pageSize))
            .pipe(
                finalize(() => this.isLoadingHoliday = false)
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                    this.createFormData();
                }
            );
    }

    createFormData() {
        this.formData = {
            id: this.holidayGroupId ?? null,
            name: this.holidayGroup?.name ?? null,
            country: this.holidayGroup?.country?.id ?? null,
        }
    }


    saveHolidayGroup(formData) {
        this.overlayService.show();

        if (this.holidayGroup) {
            this.updateHolidayGroup(formData);
        } else {
            this.createHolidayGroup(formData);
        }
    }


    createHolidayGroup(workRotationSubmit: HolidayGroupSubmit) {
        this.holidayService.postHolidayGroup(workRotationSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.router.navigate([`${routes.SITE_SETTINGS}/${routes.STATUTORY_HOLIDAYS}/${routes.EDITOR}/${res.statutoryHolidayGroupId}`]);
                    this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    updateHolidayGroup(workRotationSubmit: HolidayGroupSubmit) {
        this.holidayService.putHolidayGroup(workRotationSubmit.id, workRotationSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    navigateBack() {
        this.router.navigate([`${routes.SITE_SETTINGS}/${routes.STATUTORY_HOLIDAYS}`]);
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getHolidayDays();
    }

    getHoliday(holiday?: Holiday) {
        if(holiday === undefined){
            this.openHolidayDialog();
          }
        else {
            this.isLoadingHoliday = true;

            this.holidayService.getHolidayDay(holiday.id)
            .pipe(finalize(() => {
                this.isLoadingHoliday = false;
            }))
            .subscribe(
                holidayVerbose => {
                    this.openHolidayDialog(holidayVerbose);
                }
            );
        }
    }

    openHolidayDialog(holidayVerbose?: HolidayVerbose) {
        let formData = {
            id: holidayVerbose?.id ?? null,
            name: holidayVerbose?.name ?? null,
            startDate: holidayVerbose?.startDate ?? null,
            endDate: holidayVerbose?.endDate ?? null,
            hourOverride: holidayVerbose?.hourOverride ?? null,
            notes: holidayVerbose?.notes ?? null,
            statutoryHolidayGroup: holidayVerbose?.statutoryHolidayGroup ?? this.holidayGroupId,
            halfStat: holidayVerbose?.halfStat ?? null
        }

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          formTitle: `${this.translate.instant('Holiday')}`,
          formId: 'frm_05WeLwJ1CAVDtL',
          formData: formData,
          targetEmployeeTablePermissions: null
        };

        this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

        const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
          this.saveHoliday(event)
        });
    }

    saveHoliday(formData) {
        this.overlayService.show();

        if (formData.id !== null) {
            this.updateHoliday(formData);
        } else {
            this.createHoliday(formData);
        }
    }

    updateHoliday(holidaySubmit: HolidaySubmit) {
        this.holidayService.putHolidayDay(holidaySubmit.id, holidaySubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                    this.dialogRef.close(true);
                    this.getHolidayDays();
                    this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    createHoliday(holidaySubmit: HolidaySubmit) {
        holidaySubmit.statutoryHolidayGroup = this.holidayGroupId;

        this.holidayService.postHolidayDay(holidaySubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                    this.dialogRef.close(true);
                    this.getHolidayDays();
                    this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    deleteHolidays(holidayIds: string[]) {
        const observables = holidayIds.map(selectedItem => defer(() => this.holidayService.deleteHolidayDay(selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
            .pipe(finalize(() => this.overlayService.hide()))
            .subscribe(
                (res) => {
                    this.getHolidayDays();
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }
}
