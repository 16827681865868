<app-data-grid-comp
    [tableId]="db_tables.PerformanceReviewPlansGoalTypes"
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="GoalTypeSettings"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    [filterCategories]="filterCategories"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    [searchable]="true"
    (emitSortEvent)="sortChange($event)"
    (emitSearchEvent)="search($event)"
    (emitFilterEvent)="filterCallback($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="getReviewPlanGoalTypeWeightVerbose($event)"
    (emitDeleteEvent)="deleteReviewPlanGoalTypeWeight($event)"
></app-data-grid-comp>