import { Component, Input, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { ScheduleItem } from '@app/modules/generate-schedules/models/generate-schedules.model';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { Holiday } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import { ScheduleAbsence } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/models/employee-absence.model';
import { EmployeeAbsenceService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service';
import { TimeOffDetail } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service';
import { DateChangeEvent, SchedulerEvent } from '@progress/kendo-angular-scheduler';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const displayDate = moment().toDate();
const currentYear = new Date().getFullYear();


const randomInt = (min, max): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const parseAdjust = (eventDate: string): Date => {
  // Remove timezone from date string
  const date = new Date( eventDate.substring(0, 19) );

  return date;
};

const parseAddOneday = (eventDate: string): Date => {
  // Remove timezone from date string
  const date = new Date( eventDate.substring(0, 19) );
  date.setDate(date.getDate() + 1);

  return date;
};

const parseSubtractOneday = (eventDate: string): Date => {
  const date = new Date(eventDate);
  // date.setFullYear(currentYear);
  date.setDate(date.getDate() - 1);
  return date;
};

@Component({
  selector: 'app-employee-schedule',
  templateUrl: './employee-schedule.component.html',
  styleUrls: ['./employee-schedule.component.scss']
})
export class EmployeeScheduleComponent implements OnInit {
  @Input() employeeId: string;
  
  public selectedDate: Date = displayDate;
  from: string = moment(this.selectedDate).startOf('week').format('YYYY-MM-DD');
  to: string = moment(this.selectedDate).endOf('week').format('YYYY-MM-DD');
  isLoading: boolean = true;
  sampleData: SchedulerEvent[] = [];
  schedule: ScheduleItem[] = [];
  absences: ScheduleAbsence[] = [];
  statutoryHolidays: Holiday[] = [];
  timeOffDetail: TimeOffDetail;
  user$: any;
  datesArray = [];

  constructor(
    private generateScheduleService: GenerateScheduleService,
    private employeeAbsenceService: EmployeeAbsenceService,
    private employeeLeaveService: EmployeeLeaveService,
    private statutoryHolidaysService: StatutoryHolidaysService,
    private oidcAuthService: OidcAuthService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getTimeOffDetail();
      // this.getEmployeeSchedule();
  }

  day(date) {
    return moment(date).format('D') 
  }
  
  monthAndYear(date) {
    return moment(date).format('ddd ll')
  }

  eventsOnDate(date){
    let events = this.sampleData.filter(
      event => {
        if(date.isSame(moment(event.start), 'day') === true){
          return true;
        }
        else if( date.isAfter(moment(event.start)) && date.isSameOrBefore(moment(event.end))){
          return true;
        }
      }
    )

    return events
  }

  populateDatesArray() {
    // Loop through each day between the start and end dates
    this.datesArray = [];
    let currentDate = moment(this.from);
    while (currentDate.isSameOrBefore(moment(this.to))) {
      // Add the current date to the array
      this.datesArray.push({
        day: this.day(currentDate),
        monthAndYear: this.monthAndYear(currentDate),
        events: this.eventsOnDate(currentDate),
        isToday: currentDate.isSame(moment(), 'day')
      });

      // Move to the next day
      currentDate.add(1, 'days');
    }
  }

  dateChange(direction: string){
    if(direction === 'forward'){
      this.from = moment(this.from).add(7, 'days').format('YYYY-MM-DD');
      this.to = moment(this.to).add(7, 'days').format('YYYY-MM-DD');
    }
    else {
      this.from = moment(this.from).subtract(7, 'days').format('YYYY-MM-DD');
      this.to = moment(this.to).subtract(7, 'days').format('YYYY-MM-DD');
    }

    this.getScheduleItems();
  }

  getTimeOffDetail() {
    this.isLoading = true;

    this.employeeLeaveService.getTimeOffDetail(this.user$.userId)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.timeOffDetail = res;
            this.getScheduleItems();
        }
    );
  }

  getScheduleItems() {
    this.isLoading = true;

    let observables: Observable<any>[] = [
      // this.employeeAbsenceService.getCurrentEmployeesAbsences(this.from, this.to),
      this.employeeAbsenceService.getEmployeeAbsences(
        this.employeeId, 
        '100', 
        0, 
        null, 
        `(startDate >= "${this.from}") AND (endDate <= "${this.to}")`
      ),
      this.generateScheduleService.getEmployeeSchedule(this.employeeId, this.from, this.to)
    ];

    // If statutory holidays hasnt been loaded yet add it to the network calls
    if(this.statutoryHolidays.length === 0) {
      observables.push(this.statutoryHolidaysService.getHolidayDays(this.timeOffDetail?.statutoryHolidayGroup?.id, 0, '100'))
    }

    forkJoin(
      observables
    )
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.absences = res[0]?.data;
        this.schedule = res[1];
        // If statutory holidays was fetched
        if(res.length === 3){
          this.statutoryHolidays = res[2].data;
        }

        this.formatData();
      }
    );
  }

  formatData() {

    let schedule = this.schedule.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.start),
          startTimezone: null,
          end: parseAdjust(dataItem.end),
          endTimezone: null,
          isAllDay: true,
          title: 'Work Day',
          description: 'Work',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'Work',
      }
    ));

    let absences = this.absences.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.startDate),
          startTimezone: null,
          end: parseAdjust(dataItem.endDate),
          endTimezone: null,
          isAllDay: true,
          title: dataItem.timeOffType.name,
          description: 'Leave',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'Leave',
      }
    ));

    let statutoryHolidays = this.statutoryHolidays.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.startDate),
          startTimezone: null,
          end: parseSubtractOneday(dataItem.endDate),
          endTimezone: null,
          isAllDay: true,
          title: dataItem.name,
          description: 'StatutoryHoliday',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'StatutoryHoliday',
      }
    ));

    this.sampleData = [...schedule, ...absences, ...statutoryHolidays]

    this.populateDatesArray();
  }

}
