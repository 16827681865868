import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { routes } from '@app/consts';
import { Observable } from 'rxjs';
import { EmployeeContactCard } from './models/employee-contact-card.model';
import { EmployeeContactCardService } from './services/employee-contact-card.service';
import { features } from '@app/consts';
import { modules } from '@app/consts/modules';
@Component({
  selector: 'app-employee-contact-card',
  templateUrl: './employee-contact-card.component.html',
  styleUrls: ['./employee-contact-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeContactCardComponent implements OnInit {
  @Input() employeeId: string;

  public routes: typeof routes = routes;
  public features: typeof features = features;
  public modules: typeof modules = modules;
  
  employeeContactCard$: Observable<EmployeeContactCard>;
  panelOpenState: boolean = false;

  constructor(
    private employeeContactCardService: EmployeeContactCardService
  ) { }

  ngOnInit(): void {
    this.employeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.employeeId);
  }

  // calculateTimeHired() {
  //   if(this.currentEmploymentRecord?.hireDate){
  //     this.timeHired = moment.preciseDiff(moment(), moment(this.currentEmploymentRecord.hireDate), true);
  //   }
  // }

  // openStatusDialog() {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //   };

  //   const dialogRef = this.dialog.open(EmployeeStatusDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(
  //     data => {
  //       if(data !== false) {
  //         this.employeeStatus = data;
  //       }
  //     }
  //   );
  // }
  getLocation(workLocations: any, position: any) {
    if (Array.isArray(workLocations)) {
      let workLocation = workLocations.find(workLocation => workLocation.id === position.id);
      if (workLocation) {
        return workLocation.name;
      }
    }
    return null;
  }
}
