<mat-expansion-panel class="goal-plan mat-elevation-z0" [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h5>{{goalPlan.name}}</h5>
        </mat-panel-title>
    </mat-expansion-panel-header>
    
    <ng-template matExpansionPanelContent>
        <app-employee-published-goal-parent-data-grid 
            [goalPlan]="goalPlan" 
            [employeeId]="employeeId"
            [goalEntryAllowed]="goalEntryAllowed"
        ></app-employee-published-goal-parent-data-grid>
    </ng-template>
</mat-expansion-panel>