import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { forkJoin } from 'rxjs';
import { EmployeeAssignedGoalPlan } from '../goal-plans/models/goal-plan.model';

@Component({
  selector: 'app-employee-goal-plans',
  templateUrl: './employee-goal-plans.component.html',
  styleUrls: ['./employee-goal-plans.component.scss']
})
export class EmployeeGoalPlansComponent implements OnInit {
  @Input() employeeId: string;
  isLoading: boolean;
  goalPlans: EmployeeAssignedGoalPlan[] = [];
  filteredGoalPlans: EmployeeAssignedGoalPlan[] = [];
  goalEntryAllowedGoalPlans: EmployeeAssignedGoalPlan[] = [];

  constructor(
    public translate: TranslateService,
    private goalService: GoalPlanService
  ) {}

  ngOnInit(): void {
    this.getGoalPlans();
  }

  getGoalPlans() {
    this.isLoading = true;

    forkJoin([this.goalService.getEmployeeGoalPlans(this.employeeId, true), this.goalService.getEmployeeGoalPlans(this.employeeId, false)])
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
        (res) => {
          this.goalEntryAllowedGoalPlans = res[0];
          this.goalPlans = res[1];
          this.filteredGoalPlans = this.goalPlans;
        }
    );

    // this.goalService.getEmployeeGoalPlans(this.employeeId)
    //   .pipe(
    //     finalize( () => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe(
    //     (res) => {
    //       this.goalPlans = res;
    //     }
    //   );
  }

  isGoalEntryAllowed(goalPlan): boolean{
    let foundObject = this.goalEntryAllowedGoalPlans.find(obj => obj.id === goalPlan.id);
    let objectExists = !!foundObject;

    return objectExists;
  }

  filterPlans(searchTerm: string) {
    this.filteredGoalPlans = this.goalPlans.filter(plan =>
      plan.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
