<ng-container *ngIf="isLoading; else loaded">
    <mat-card>
        <mat-card-header></mat-card-header>
        <mat-card-content>
            <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
            ></ngx-skeleton-loader>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-template #loaded>
    <div class="employee-goal-plans-container">
        <mat-form-field class="search-field" appearance="fill">
            <input 
                matInput
                [placeholder]="translate.instant('Search')"
                [(ngModel)]="searchValue"
                (input)="filterPlans($event.target.value)"
            >
            <mat-icon matPrefix class="material-icons-outlined">
                search
            </mat-icon>
        </mat-form-field>
    
        <app-employee-goal-plan
            *ngFor="let goalPlan of filteredGoalPlans; let first = first" 
            [expanded]="first" 
            class="goal-plan"
            [employeeId]="employeeId"
            [goalPlan]="goalPlan"
            [goalEntryAllowed]="isGoalEntryAllowed(goalPlan)"
        ></app-employee-goal-plan>
    
        <div *ngIf="goalPlans === undefined || goalPlans.length === 0" class="no-goal-plans-container">
            <h6 appLocalizationPopupDirective localizationCode="NoGoalPlansAssigned">
                {{ 'NoGoalPlansAssigned' | translate: {Default: "No Goal Plans Assigned"} }}
            </h6>
        </div>
    </div>
</ng-template>