import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReviewPlanReviewer, ReviewPlanReviewerSecurity, ReviewPlanReviewerSubmit, ReviewerSecurityPermission } from '../../../../models/review-plan.model';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ReviewPlansService } from '../../../../services/review-plans.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { BehaviorSubject, Observable, finalize, from } from 'rxjs';
import * as _ from 'lodash';
import { RowArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-review-plan-reviewer-dialog',
  templateUrl: './review-plan-reviewer-dialog.component.html',
  styleUrls: ['./review-plan-reviewer-dialog.component.scss']
})
export class ReviewPlanReviewerDialogComponent implements OnInit {
  reviewPlanReviewer: ReviewPlanReviewer;
  reviewPlanReviewerId: string;
  reviewPlanId: string;
  loadingReviewPlanReviewer: boolean = true;

  selectReviewerTypeFormData: any;
  getSelectReviewerTypeFormData: boolean = false;
  selectReviewerTypeFormValid: boolean = false;
  selectReviewerTypeFormPristine: boolean = true;

  selectReviewerSettingsFormData: any;
  getselectReviewerSettingsFormData: boolean = false;
  selectReviewerSettingsFormValid: boolean = false;
  selectReviewerSettingsFormPristine: boolean = true;

  selectReviewerIdFormData: any;
  getselectReviewerIdFormData: boolean = false;
  selectReviewerIdFormValid: boolean = false;
  selectReviewerIdFormPristine: boolean = true;
  reviewers: ReviewPlanReviewer[];

  originalReviewerSecurity: ReviewPlanReviewerSecurity[] = [];
  reviewerSecurity: any[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ReviewPlanReviewerDialogComponent>,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.reviewPlanId = data.reviewPlanId;
    this.reviewPlanReviewerId = data.reviewPlanReviewer?.id;
  }

  ngOnInit(): void {
    this.getReviewPlanReviewer();
    this.getReviewers();
  }

  getReviewPlanReviewer() {
    if (this.reviewPlanReviewerId != null) {
      this.loadingReviewPlanReviewer = true;

      this.reviewPlansService.getReviewPlanReviewer(this.reviewPlanId, this.reviewPlanReviewerId)
      .pipe(
          finalize(() => {
              this.loadingReviewPlanReviewer = false;
              this.buildForms();
          })
      )
      .subscribe(
          (res) => {
              this.reviewPlanReviewer = res;
          }
      );
    } else {
      this.originalReviewerSecurity = [];
      this.loadingReviewPlanReviewer = false;
    }
  }

  buildForms() {
    this.originalReviewerSecurity = this.reviewPlanReviewer?.security ? _.cloneDeep(this.reviewPlanReviewer?.security) : [];

    this.selectReviewerTypeFormData = {
      reviewerTypeId: this.reviewPlanReviewer ? this.reviewPlanReviewer.reviewerType?.id : null,
    }

    this.selectReviewerIdFormData = {
      reviewerId: this.reviewPlanReviewer ? this.reviewPlanReviewer.reviewer?.id : null,
    }

    this.selectReviewerSettingsFormData = {
      id: this.reviewPlanReviewer ? this.reviewPlanReviewer.id : null,
      orderNumber : this.reviewPlanReviewer ? this.reviewPlanReviewer.orderNumber : null,
      rating: this.reviewPlanReviewer ? this.reviewPlanReviewer.rating?.id : null,
      comments: this.reviewPlanReviewer ? this.reviewPlanReviewer.comments?.id : null,
      goalPlanOverallRating: this.reviewPlanReviewer ? this.reviewPlanReviewer.goalPlanOverallRating?.id : null,
      goalPlanOverallComments: this.reviewPlanReviewer ? this.reviewPlanReviewer.goalPlanOverallComments?.id : null,
      overallRatingSecurity: this.reviewPlanReviewer ? this.reviewPlanReviewer.overallRating?.id : null,
      overallCommentsSecurity: this.reviewPlanReviewer ? this.reviewPlanReviewer.overallComments?.id : null,
      includeCalculatedRatings: this.reviewPlanReviewer ? this.reviewPlanReviewer.includeCalculatedRatings : false,
    }
  }

  compareById(f1: ReviewerSecurityPermission, f2: ReviewerSecurityPermission): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  get formsInvalid() {
    if(this.selectReviewerTypeFormData?.reviewerTypeId === 'SpecifiedUser') {
      return (this.selectReviewerTypeFormValid && this.selectReviewerSettingsFormValid && this.selectReviewerIdFormValid) !== true;
    }
    else {
      return (this.selectReviewerTypeFormValid && this.selectReviewerSettingsFormValid) !== true;
    }
  }

  getReviewers() {
    from(this.reviewPlansService.getAllReviewPlanReviewers(this.reviewPlanId))
    .pipe(
      finalize(() => {})
    )
    .subscribe(
        (res) => {
          // this.originalReviewers = _.cloneDeep(this.addMissingSecurityValues(res.data));
          this.reviewers = _.cloneDeep(res.data);
          this.addMissingSecurityValues(this.reviewers);
          // this.originalReviewerSecurity = _.cloneDeep(this.reviewPlanReviewer.security);
          this.reviewerSecurity = _.cloneDeep(this.originalReviewerSecurity);
          this.filterReviewers();
        }
    );
  }

  addMissingSecurityValues(reviewers: ReviewPlanReviewer[]) {
    // Add missing original reviewer security
    this.addMissingOriginalReviewerSecurity(reviewers);

    // Add missing reviewer security items
    this.addMissingReviewerSecurityItems(reviewers);
  }

  addMissingOriginalReviewerSecurity(reviewers: ReviewPlanReviewer[]) {
    // Map reviewer types to avoid repeated if-else
    const reviewerTypeMap = {
      SpecifiedUser: "SpecifiedUser",
      Manager: "Manager",
      SecondLevelManager: "SecondLevelManager",
      Employee: "Employee",
    };

    // Iterate through reviewers
    reviewers.forEach((reviewer) => {
      const reviewerType = reviewer.reviewerType?.id;

      // Check if original reviewer security exists for the reviewer type
      const exists = this.originalReviewerSecurity.findIndex(
        (rs) => rs.reviewerType?.id === reviewerType
      ) !== -1;

      // If not, add original reviewer security
      if (!exists) {
        this.originalReviewerSecurity.push(this.addReviewerSecurity(reviewer));
      }
    });
  }

  addMissingReviewerSecurityItems(reviewers: ReviewPlanReviewer[]) {
    // Iterate through reviewers
    reviewers.forEach((reviewer) => {
      // Iterate through original reviewer security
      this.originalReviewerSecurity.forEach((reviewerSecurity) => {
        const reviewerType = reviewer.reviewerType?.id;
        const forReviewerType = reviewerSecurity.reviewerType?.id;

        // Check if reviewer type and forReviewer type are different
        if (reviewerType && forReviewerType && reviewerType !== forReviewerType) {
          // Check if security item exists for the reviewer type
          const securityExists =
            reviewerSecurity.security.findIndex(
              (rs) => rs.reviewerType?.id === reviewerType
            ) !== -1;

          // If not, add security item
          if (!securityExists) {
            reviewerSecurity.security.push(
              this.buildDefaultReviewerSecurityItem(reviewer)
            );
          }
        }
      });
    });
  }

  addReviewerSecurity(reviewer: ReviewPlanReviewer) {
    return {
      reviewerType: {
        id: reviewer?.reviewerType?.id,
        name: reviewer?.reviewerType?.name
      },
      reviewer: {
        id: reviewer?.reviewer?.id,
        firstName: reviewer?.reviewer?.firstName,
        lastName: reviewer?.reviewer?.lastName
      },
      security: []
    }
  }

  buildDefaultReviewerSecurityItem(reviewer: ReviewPlanReviewer) {
    return {
      reviewerType: {
        id: reviewer?.reviewerType?.id,
        name: reviewer?.reviewerType?.name
      },
      reviewer: {
        id: reviewer?.reviewer?.id,
        firstName: reviewer?.reviewer?.firstName,
        lastName: reviewer?.reviewer?.lastName
      },
      viewComments: false,
      viewRating: false
    }
  }

  filterReviewers() {
    this.reviewerSecurity =  _.cloneDeep(this.originalReviewerSecurity);

    // if(this.selectReviewerTypeFormData?.reviewerTypeId === 'SpecifiedUser'){
    //   // remove all the reviewers that match the selected reviewer
    //   this.reviewerSecurity = this.reviewerSecurity.filter( reviewerSecurity => reviewerSecurity.reviewer?.id !== this.selectReviewerIdFormData?.reviewerId )
    // }
    // else


    // if(this.selectReviewerTypeFormData?.reviewerTypeId === 'Employee'){
    //   this.reviewerSecurity = this.reviewerSecurity.filter( reviewerSecurity => reviewerSecurity.reviewerType?.id !== 'Employee' )
    // }
    // else if(this.selectReviewerTypeFormData?.reviewerTypeId === 'Manager'){
    //   this.reviewerSecurity = this.reviewerSecurity.filter( reviewerSecurity => reviewerSecurity.reviewerType?.id !== 'Manager' )
    // }
    // else if(this.selectReviewerTypeFormData?.reviewerTypeId === 'SecondLevelManager'){
    //   this.reviewerSecurity = this.reviewerSecurity.filter( reviewerSecurity => reviewerSecurity.reviewerType?.id !== 'SecondLevelManager' )
    // }
  }

  emitFormDataChange(formData, section: string) {
    if(section === 'selectReviewerType') {
      this.selectReviewerTypeFormData = formData;

      this.filterReviewers();

      // If you change the reviewer type and it isnt a specific user, clear the reviewer form data
      if(this.selectReviewerTypeFormData?.reviewerTypeId !== 'SpecifiedUser'){
        this.selectReviewerIdFormData = null;
      }
    }
    else if(section === 'selectReviewerId') {
      this.selectReviewerIdFormData = formData;
      this.filterReviewers();

    }
    else if(section === 'selectReviewerSettings') {
      this.selectReviewerSettingsFormData = formData;
    }
  }

  formDataEmitted(formData, section: string) {
    if(section === 'selectReviewerType') {
      this.selectReviewerTypeFormData = formData;
    }
    else if(section === 'selectReviewerId') {
      this.selectReviewerIdFormData = formData;
    }
    else if(section === 'selectReviewerSettings') {
      this.selectReviewerSettingsFormData = formData;
    }
  }

  formStatusUpdated(formValid, section: string) {
    if(section === 'selectReviewerType') {
      this.selectReviewerTypeFormValid = formValid;
    }
    else if(section === 'selectReviewerId') {
      this.selectReviewerIdFormValid = formValid;
    }
    else if(section === 'selectReviewerSettings') {
      this.selectReviewerSettingsFormValid = formValid;
    }
  }

  formPristineEmitted(formPristine: boolean, section: string) {
    if(section === 'selectReviewerType') {
      this.selectReviewerTypeFormPristine = formPristine;
    }
    else if(section === 'selectReviewerId') {
      this.selectReviewerIdFormPristine = formPristine;
    }
    else if(section === 'selectReviewerSettings') {
      this.selectReviewerSettingsFormPristine = formPristine;
    }
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.dialogRef.close();
        }
      }
    );
  }

  save() {
    let formData: ReviewPlanReviewerSubmit = {
      id: this.reviewPlanReviewer?.id,
      reviewerTypeId: this.selectReviewerTypeFormData?.reviewerTypeId,
      reviewerId: this.selectReviewerIdFormData?.reviewerId,
      orderNumber : this.selectReviewerSettingsFormData?.orderNumber,
      includeCalculatedRatings: this.selectReviewerSettingsFormData?.includeCalculatedRatings,
      overallComments: this.selectReviewerSettingsFormData?.overallCommentsSecurity,
      overallRating: this.selectReviewerSettingsFormData?.overallRatingSecurity,
      goalPlanOverallRating: this.selectReviewerSettingsFormData?.goalPlanOverallRating,
      goalPlanOverallComments: this.selectReviewerSettingsFormData?.goalPlanOverallComments,
      rating: this.selectReviewerSettingsFormData?.rating,
      comments: this.selectReviewerSettingsFormData?.comments,
      security: this.reviewerSecurity.map(item => {
        return {
          reviewerUser: item.reviewer?.id ?? null,
          reviewerType: item.reviewerType?.id,
          overallCommentsSecurity: item.overallCommentsSecurity?.id,
          overallRatingSecurity: item.overallRatingSecurity?.id,
          security: item.security.map(i => ({
            targetReviewerUser: i.reviewerType?.id === "SpecifiedUser" ? i.reviewer?.id : null,
            targetReviewerType: i.reviewerType?.id,
            viewComments: i.viewComments,
            viewRating: i.viewRating
          }))
        };
      })
    };

    if(this.reviewPlanReviewer) {
      this.updateReviewPlanReviewer(formData);
    }
    else {
      this.createNewReviewPlanReviewer(formData);
    }
  }

  createNewReviewPlanReviewer(formData: ReviewPlanReviewerSubmit){
    this.overlayService.show();

    this.reviewPlansService.addReviewPlanReviewer(this.reviewPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
    );
  }

  updateReviewPlanReviewer(formData: ReviewPlanReviewerSubmit){
    this.overlayService.show();

    this.reviewPlansService.updateReviewPlanReviewer(this.reviewPlanId, formData.id, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
    );
  }

  close(){
    (this.selectReviewerTypeFormPristine && this.selectReviewerSettingsFormPristine && this.selectReviewerIdFormPristine) ? this.dialogRef.close() : this.openConfirmCloseDialog();
  }

}
