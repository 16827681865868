import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import * as moment from 'moment';
import { FilterService } from '../filter-services/filter-service';
import { from } from 'rxjs';
import { ReviewRatingsService } from '@app/modules/performance/components/review-ratings/services/review-ratings.service';

@Component({
  selector: 'filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  operators = {
    "String": [
      {
        label: 'Like',
        value: 'like'
      },
      {
        label: 'Starts With',
        value: 'startswith'
      },
      {
        label: 'Ends With',
        value: 'endswith'
      },
    ],
    "Number": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "Percentage": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "Date": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "Lookup": [
      {
        label: '=',
        value: '='
      },
    ],
    "ProvidedList": [
      {
        label: '=',
        value: '='
      },
    ],
    "SpecialLookup": [
      {
        label: '=',
        value: '='
      },
    ],
  }

  selectedCategory: any;
  categories = [];

  filteredList: any[] = [];
  originalList: any[] = [];
  isLoading: boolean;

  filter: {
    id: string,
    filterCategory: any,
    filterOperator: any,
    filterString: any
  };

  providedListOptions: boolean = false;
  providedListId: any;
  providedListDisplayName: any;
  providedList: any;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private filterService: FilterService,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private reviewRatingsService: ReviewRatingsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.categories = data.categories.sort((a,b) => a.title > b.title ? 1 : -1);
    this.filter = data.filter;
  }

  ngOnInit(): void {
    // Needed to avoid init errors
    this.selectedCategory = {
      dataType: ""
    }
    this.createForm();
  }

  createForm() {

    this.form = this.fb.group({
      id: [this.filter ? this.filter.id : this.generateID()],
      filterCategory: [this.filter ? this.filter.filterCategory : null, Validators.required],
      filterOperator: [this.filter ? this.filter.filterOperator : null, Validators.required],
      filterString: [this.filter ? this.filter.filterString : null, Validators.required],
    });


    if (this.filter) {
      this.isLoading = true;
      this.selectedCategory = this.filter.filterCategory


      if(this.filter.filterCategory.dataType == "ProvidedList"){
        this.providedListOptions = true;
        this.providedList = this.filter.filterCategory.listOptions.list;
        this.providedListId = this.filter.filterCategory.listOptions.id;
        this.providedListDisplayName = this.filter.filterCategory.listOptions.name;
      }
      else if(this.filter.filterCategory.dataType == "Percentage") {
        this.form.get('filterString').setValue((this.filter.filterString * 100).toFixed(0));
      }

      if (this.filter.filterCategory.lookupCode == "ORGANISATION") {
        this.filterService.getOrganizations().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.lookupCode == "WORK_LOCATION") {
        this.filterService.getWorkLocations().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      }
      else if (this.filter.filterCategory.lookupCode == "WORK_ROTATION") {
        this.filterService.getWorkRotations().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      }
      else if (this.filter.filterCategory.lookupCode == "CostCenter") {
        this.filterService.getCostCenters().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.lookupCode == "Country") {
        this.filterService.getCountries().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.lookupCode == "POSITION") {
        this.filterService.getPositions().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.lookupCode == "GOAL_TYPE") {
        this.filterService.getGoalTypes().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.lookupCode == "GOAL_PLAN") {
        this.filterService.getGoalPlans().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      }
      else if (this.filter.filterCategory.lookupCode == "GOAL_TYPE_FIELD_SETTING") {
        this.filterService.getGoalTypeFieldSettings().subscribe(values => {
          this.populateSelectFieldSettings(values, this.filter.filterString.id)
        })
      }
      else if (this.filter.filterCategory.lookupCode == "MODULES") {
        this.filterService.getModules().subscribe(values => {
          this.populateSelectSpecial(values, this.filter.filterString.id)
        })
      } 
      else if (this.filter.filterCategory.dataType == "Lookup") {
        from(this.lookupService.getListOptions(this.filter.filterCategory.lookupCode)).subscribe(pagedData => {
          this.filteredList = pagedData.data;
          this.originalList = pagedData.data;
          this.filteredList.forEach((val, idx) => {
            if (val.id == this.filter.filterString.id) {
              this.form.get('filterString').setValue(this.filteredList[idx]);
              this.isLoading = false;
            }
          })
        });
      }
    }
  }

  populateSelectSpecial(values: any, select: number) {
    let displayable = []
    let selectedId = 0
    values.forEach(pos => {
      displayable.push({ id: pos.id, name: pos.name })
      if (select && pos.id == select) {
        selectedId = displayable.length - 1
      }
    })
    this.filteredList = displayable
    this.originalList = displayable
    if (select) {
      this.form.get('filterString').setValue(this.filteredList[selectedId]);
    }
    this.isLoading = false;
  }

  populateSelectFieldSettings(values: any, select: number) {
    let displayable = []
    let selectedId = 0
    values.forEach(pos => {
      displayable.push({ id: pos.id, name: pos.description })
      if (select && pos.id == select) {
        selectedId = displayable.length - 1
      }
    })
    this.filteredList = displayable
    this.originalList = displayable
    if (select) {
      this.form.get('filterString').setValue(this.filteredList[selectedId]);
    }
    this.isLoading = false;
  }

  generateID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  getFilterStringValue(filter: any) {
    if (filter.filterString.id) {
      return filter.filterString.id;
    }
    else {
      return filter.filterString;
    }
  }

  // create getter for form-property i.e.
  get filterCategory(): AbstractControl {
    return this.form.get('filterCategory')
  }

  get filterString(): AbstractControl {
    return this.form.get('filterString')
  }

  operatorChange(event) {
    this.selectedCategory = event.value;
    this.isLoading = true;

    if (event.value.dataType == "Lookup") {
      this.form.get('filterOperator').setValue(this.operators[this.selectedCategory.dataType][0].value);
      
      from(this.lookupService.getListOptions(event.value.lookupCode)).subscribe(pagedData => {
        this.filteredList = pagedData.data;
        this.originalList = pagedData.data;
        this.isLoading = false;
      });
    } else if (event.value.dataType == "SpecialLookup") {
      this.form.get('filterOperator').setValue(this.operators[this.selectedCategory.dataType][0].value);
      
      if (event.value.lookupCode == "ORGANISATION") {
        this.filterService.getOrganizations().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "CostCenter") {
        this.filterService.getCostCenters().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "WORK_LOCATION") {
        this.filterService.getWorkLocations().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "WORK_ROTATION") {
        this.filterService.getWorkRotations().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "POSITION") {
        this.filterService.getPositions().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      }
      else if (event.value.lookupCode == "GOAL_TYPE") {
        this.filterService.getGoalTypes().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "GOAL_PLAN") {
        this.filterService.getGoalPlans().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      }
      else if (event.value.lookupCode == "GOAL_TYPE_FIELD_SETTING") {
        this.filterService.getGoalTypeFieldSettings().subscribe(values => {
          this.populateSelectFieldSettings(values, null)
        })
      }
      else if (event.value.lookupCode == "MODULES") {
        this.filterService.getModules().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      } 
      else if (event.value.lookupCode == "REVIEW_PLAN") {
        this.filterService.getReviewPlans().subscribe(values => {
          this.populateSelectSpecial(values, null)
        })
      }
      else if (event.value.lookupCode == "REVIEW_RATING") {
        from(this.reviewRatingsService.getAllReviewRatings()).subscribe(values => {
          this.populateSelectSpecial(values.data, null)
        })
      }
    }
    else if (event.value.dataType == "ProvidedList" || event.value.listOptions) {
      this.providedListOptions = true;
      this.providedList = event.value.listOptions.list;
      this.providedListId = event.value.listOptions.id;
      this.providedListDisplayName = event.value.listOptions.name;
    }
    else {
      this.filteredList = [];
      this.originalList = [];
      this.isLoading = false;
    }
  }

  save() {
    if(this.form.value.filterCategory.dataType === 'Date'){
      this.form.patchValue(
        {
          filterString: moment(this.form.value.filterString).format("YYYY-MM-DD")
        }
      )
    }
    else if(this.form.value.filterCategory.dataType === 'Percentage'){
      let percentage;
      if (this.form.value.filterString >= 0 && this.form.value.filterString <= 100) {
        percentage = (this.form.value.filterString / 100).toFixed(2);
        
        this.form.patchValue(
          {
            filterString: percentage
          }
        )
      }
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  onDropdownSearchKeyReceived(value: string, data:any) {
    if (value) {
      let filter = value.toLowerCase();
      this.filteredList = data.filter(option => option.name.toLowerCase().includes(filter));
    } else {
      this.filteredList = this.originalList ;
    }
  }

}
