import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalDialogComponent } from '../goal-dialog/goal-dialog.component';
import { EmployeeGoal, EmployeeGoalSubmit } from '../goal-plans/models/goal-plan.model';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { TranslationService } from '@app/core/services/translation.service';
import { PersonalGoalDialogComponent } from '../personal-goal-dialog/personal-goal-dialog.component';

@Component({
  selector: 'app-employee-goals',
  templateUrl: './employee-goals.component.html',
  styleUrls: ['./employee-goals.component.scss']
})
export class EmployeeGoalsComponent implements OnInit {
  @Input() employeeId: string;

  public columns: any[] = [];

  filterCategories: any[] = [];

  sortableColumns: any[] = [
    // { field: "description", sortValue: "description"},
    { field: "expectedCompletionDate", sortValue: "expectedCompletionDate"},
    { field: "actualCompletionDate", sortValue: "actualCompletionDate"},
    { field: "goalType", sortValue: "goalTypeName"},
    { field: "goalPlan", sortValue: "goalPlanName"},
    { field: "percentageComplete", sortValue: "percentageComplete"},
    { field: "weight", sortValue: "weight"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  user$: any;

  getGoalsRequest: any;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  translationsLoaded: boolean = false;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private translationService: TranslationService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private goalService: GoalPlanService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.view = this.getGoals();

    // If translations are already loaded set the columns
    if(this.translationService.currentTranslations !== null){
      this.translationsLoaded = true;
      this.setColumnsWithTranslations();
    }

    // once the translations for the app have loaded set the columns to the correct translation values
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translationsLoaded = true;
      this.setColumnsWithTranslations();
    });
  }
  
  setColumnsWithTranslations() {
    this.columns = [
      { field: "isPersonalGoal", title: this.translate.instant("EmployeeGoals-Category"), type: 'chip', chipValue: [true, '',  '', false], chipBooleanText: ['PersonalGoal', 'PublishedGoal'] },
      { field: "objective", tableId: "tfi_PEgObjective", title: this.translate.instant("EmployeeGoals-Objective") },
      { field: "description", tableId: "tfi_PEgDescription", title: this.translate.instant("EmployeeGoals-Description"), type: "longText", longTextLimit: 200 },
      { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: this.translate.instant("EmployeeGoals-ExpectedCompletionDate"), type: "date" },
      { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: this.translate.instant("EmployeeGoals-ActualCompletionDate"), type: "date" },
      { field: "parent", subField: "objective", tableId: "tfi_PEgParentGoal", title: this.translate.instant("EmployeeGoals-Parent") },
      { field: "goalPlan", subField: "name", tableId: "", title: this.translate.instant("EmployeeGoals-GoalPlan") },
      { field: "goalType", subField: "name", tableId: "tfi_PEgGoalType", title: this.translate.instant("EmployeeGoals-GoalType"),},
      { field: "weight", tableId: "tfi_PEgWeight", title: this.translate.instant("EmployeeGoals-Weight"), type: "percentage", percentageMaxValue: 1 },
      { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: this.translate.instant("EmployeeGoals-PercentageComplete"), type: "percentage", percentageMaxValue: 1 },
    ]

    this.filterCategories = [
      { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: this.translate.instant("EmployeeGoals-ExpectedCompletionDate"), type: "date", dataType: 'Date'},
      { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: this.translate.instant("EmployeeGoals-ActualCompletionDate"), type: "date", dataType: 'Date'},
      { field: "GoalTypeId", subField: "name", tableId: "tfi_PEgGoalType", title: this.translate.instant("EmployeeGoals-GoalType"), dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    ]
  }

  getGoals() {
    this.isLoading = true;

    let filter;
    // let filter = "(GoalPlanId = null)";
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsRequest = this.goalService.getEmployeeGoals(this.employeeId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          res.employeeGoals.data.map( employeeGoal => employeeGoal.employee.id = res.employeeId )

          this.gridDataResult = {
            data: res.employeeGoals.data,
            total: res.employeeGoals.total,
          }

          this.gridDataResult.data.forEach(
            goal => {
              if(goal.createdBy.id === this.employeeId) {
                goal.isPersonalGoal = true;
              }
              else {
                goal.isPersonalGoal = false;
              }
            }
          )

          this.gridData = res.employeeGoals.data;
        }
      );
  }

  getGoalVerbose(goal?: EmployeeGoal) {
    if(goal === undefined){
      this.openGoalDialog();
    }
    else {
      let employeeGoal: EmployeeGoal;

      this.isLoading = true;

      this.goalService.getEmployeeGoal(this.employeeId, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          employeeGoal = res;
          this.openGoalDialog(employeeGoal);
        }
      );
    }
  }

  openGoalDialog(goal?: EmployeeGoal) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goal: goal,
      employeeId: goal?.employee?.id || this.employeeId
    };

    this.dialogRef = this.dialog.open(PersonalGoalDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGoals();
        }
      }
    );

  }

  // openGoalDialog(goal?: EmployeeGoal) {
  //   let formData = {
  //     id: goal ? goal.id : null,
  //     description: goal ? goal.description : null,
  //     percentageComplete: goal ? goal.percentageComplete : null,
  //     expectedCompletionDate: goal ? goal.expectedCompletionDate : null,
  //     actualCompletionDate: goal ? goal.actualCompletionDate : null,
  //     goalTypeId: goal ? goal.goalType?.id : null,
  //   };

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     formTitle: `${this.translate.instant('Goal')}`,
  //     formId: 'frm_iel8cV73NvPu4w',
  //     formData: formData
  //   };

  //   this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

  //   const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
  //     goal ? this.updateGoal(event) : this.createNewGoal(event)
  //   });
  // }

  createNewGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  deleteGoals(goalIds: string[]) {
    const observables = goalIds.map(selectedItem => defer(() => this.goalService.deleteEmployeeGoal(this.employeeId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGoals();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoals();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoals();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Objective like "${variant}")`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getGoals();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoals();
  }
}
