<div class="container">
    <div class="left goal-plans">
        <h6 appLocalizationPopupDirective localizationCode="Review-GoalPlans">
            {{ 'Review-GoalPlans' | translate: {Default: 'Goal Plans'} }}
        </h6>

        <mat-list role="list">
            <mat-list-item *ngIf="review.goalPlans === null || review.goalPlans.length === 0">
                <span appLocalizationPopupDirective localizationCode="Review-NoGoalPlans">
                    {{ 'Review-NoGoalPlans' | translate: {Default: 'No Goal Plans'} }}
                </span>
            </mat-list-item>
            
            <ng-container *ngFor="let goalPlan of review.goalPlans; let last = last">
                <mat-list-item
                    role="listitem"
                    [ngClass]="{
                        'selected-goal-plan': selectedGoalPlan.id == goalPlan.id
                    }"
                    (click)="selectedGoalPlan = goalPlan"
                >
                    <div class="top">
                        <div matListItemTitle>
                            {{goalPlan?.name}}
                        </div>

                        <mat-icon *ngIf="goalPlan.overallStatus === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                        <mat-icon *ngIf="goalPlan.overallStatus === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>

                    </div>
                    <div matListItemLine class="weight" *ngIf="goalPlan?.weight">
                        {{ 'Weight' | translate: {Default: 'Weight'} }} - {{ goalPlan?.weight }}% <mat-progress-bar mode="determinate" [value]="goalPlan?.weight"></mat-progress-bar>
                    </div>
                </mat-list-item>

                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </mat-list>
    </div>
    <div class="right goals">

        <mat-tab-group class="mat-tab-group" *ngIf="selectedGoalPlan">

            <mat-tab>
                <ng-template mat-tab-label>
                  <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Review-Goals">
                    {{ 'Review-Goals' | translate: {Default: 'Goals'} }}

                    <mat-icon *ngIf="selectedGoalPlan.goalsStatus === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                    <mat-icon *ngIf="selectedGoalPlan.goalsStatus === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>
                  </div>
                </ng-template>
  
                <ng-container *ngIf="selectedGoalPlan?.goals === null || selectedGoalPlan?.goals.length === 0; else showGoals">
                    <div class="no-goals">
                        <div appLocalizationPopupDirective localizationCode="Review-NoGoals">{{ 'Review-NoGoals' | translate: {Default: 'No Goals For This Goal Plan'} }}</div>
                    </div>
                </ng-container>
                <ng-template #showGoals>
                    <mat-expansion-panel *ngFor="let goal of selectedGoalPlan.goals; let first = first" [expanded]="first" class="goal">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{goal.objective}}
                            </mat-panel-title>
                            <mat-panel-description>
                                <div class="k-flex-grow"></div>
        
                                <!-- <mat-icon *ngIf="calculateSliderPercentage( goal.percentageComplete, 1 ) === 100" class="goal-complete material-icons-outlined">check_circle</mat-icon> -->
                                <ng-container></ng-container>
        
                                <mat-icon *ngIf="goal.status === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                                <mat-icon *ngIf="goal.status === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
        
                        <ng-template matExpansionPanelContent>
                            <div class="goal-details">
                                <div>
                                    <div class="label">{{getLabel('tfi_PEgDescription')}}</div>
                                    <div class="value">
                                        {{goal.description}}
                                    </div>
                                </div>

                                <div>
                                    <div class="label">{{getLabel('tfi_PEgGoalType')}}</div>
                                    <div class="value">
                                        {{goal.goalType?.name}}
                                    </div>
                                </div>
        
                                <div>
                                    <div class="label">{{getLabel('tfi_PEgPercentageComplete')}}</div>
                                    <div class="value">
                                        <div class="percentage-column">
                                            <span class="percentage-value" *ngIf="goal.percentageComplete !== null">
                                                {{ calculateSliderPercentage( goal.percentageComplete, 1 ) }}%
                                            </span>
                                            <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( goal.percentageComplete, 1 )"></mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
        
                                <div>
                                    <div class="label">{{getLabel('tfi_PEgWeight')}}</div>
                                    <div class="value">
                                        <div class="percentage-column">
                                            <span class="percentage-value" *ngIf="goal.weight !== null">
                                                {{ calculateSliderPercentage( goal.weight, 1 ) }}%
                                            </span>
                                            <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( goal.weight, 1 )"></mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
        
                                <div>
                                    <div class="label">{{getLabel('tfi_PEgExpectedCompletionDate')}}</div>
                                    <div class="value">
                                        {{goal.expectedCompletionDate | date:'mediumDate'}}
                                    </div>
                                </div>
        
                                <div>
                                    <div class="label">{{getLabel('tfi_PEgActualCompletionDate')}}</div>
                                    <div class="value">
                                        {{goal.actualCompletionDate | date:'mediumDate'}}
                                    </div>
                                </div>
        
                            </div>
        
                            <div class="reviews" *ngIf="goal?.reviews?.length > 0">
                                <div class="header" appLocalizationPopupDirective localizationCode="Reviews">
                                    {{ 'Reviews' | translate: {Default: 'Reviews'} }}
                                </div>
                                <ng-container *ngIf="goal?.reviews === null || goal?.reviews?.length === 0; else showReviews">
                                    <div class="no-reviews" appLocalizationPopupDirective localizationCode="Review-NoGoalReviews">
                                        <div>{{ 'Review-NoGoalReviews' | translate: {Default: 'No Reviews For this Goal'} }}</div>
                                    </div>
                                </ng-container>
                                <ng-template #showReviews>
                                    <div *ngFor="let review of goal.reviews; let last = last" class="review">
                                        <ng-container *ngIf="showReview(review)">
                                            <app-review-goal-review
                                                [review]="review"
                                                [settings]="settings"
                                                [goalPlan]="selectedGoalPlan"
                                                [goalId]="goal?.id"
                                                [goalTypeId]="goal?.goalType?.id"
                                                (saveReview)="saveGoalReview.emit($event)"
                                            ></app-review-goal-review>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="child-goals" *ngIf="goal.childGoals !== undefined && goal.childGoals.length > 0">
                                <h6 appLocalizationPopupDirective localizationCode="Review-ChildGoals">
                                    {{ 'Review-ChildGoals' | translate: {Default: 'Child Goals'} }}
                                </h6>

                                <mat-expansion-panel *ngFor="let childGoal of goal.childGoals; let first = first" [expanded]="first" class="goal">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{childGoal.objective}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <div class="k-flex-grow"></div>
                    
                                            <!-- <mat-icon *ngIf="calculateSliderPercentage( goal.percentageComplete, 1 ) === 100" class="goal-complete material-icons-outlined">check_circle</mat-icon> -->
                                            <ng-container></ng-container>
                    
                                            <mat-icon *ngIf="childGoal.status === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                                            <mat-icon *ngIf="childGoal.status === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                    
                                    <ng-template matExpansionPanelContent>
                                        <div class="goal-details">
                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgDescription')}}</div>
                                                <div class="value">
                                                    {{childGoal.description}}
                                                </div>
                                            </div>

                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgGoalType')}}</div>
                                                <div class="value">
                                                    {{childGoal.goalType?.name}}
                                                </div>
                                            </div>
                    
                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgPercentageComplete')}}</div>
                                                <div class="value">
                                                    <div class="percentage-column">
                                                        <span class="percentage-value" *ngIf="goal.percentageComplete !== null">
                                                            {{ calculateSliderPercentage( childGoal.percentageComplete, 1 ) }}%
                                                        </span>
                                                        <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( childGoal.percentageComplete, 1 )"></mat-progress-bar>
                                                    </div>
                                                </div>
                                            </div>
                    
                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgWeight')}}</div>
                                                <div class="value">
                                                    <div class="percentage-column">
                                                        <span class="percentage-value" *ngIf="goal.weight !== null">
                                                            {{ calculateSliderPercentage( childGoal.weight, 1 ) }}%
                                                        </span>
                                                        <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( childGoal.weight, 1 )"></mat-progress-bar>
                                                    </div>
                                                </div>
                                            </div>
                    
                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgExpectedCompletionDate')}}</div>
                                                <div class="value">
                                                    {{childGoal.expectedCompletionDate | date:'mediumDate'}}
                                                </div>
                                            </div>
                    
                                            <div>
                                                <div class="label">{{getLabel('tfi_PEgActualCompletionDate')}}</div>
                                                <div class="value">
                                                    {{childGoal.actualCompletionDate | date:'mediumDate'}}
                                                </div>
                                            </div>
                    
                                        </div>
                    
                                        <div class="reviews" *ngIf="childGoal?.reviews?.length > 0">
                                            <div class="header" appLocalizationPopupDirective localizationCode="Reviews">
                                                {{ 'Reviews' | translate: {Default: 'Reviews'} }}
                                            </div>
                                            <ng-container *ngIf="childGoal?.reviews === null || childGoal?.reviews?.length === 0; else showReviews">
                                                <div class="no-reviews" appLocalizationPopupDirective localizationCode="Review-NoGoalReviews">
                                                    <div>{{ 'Review-NoGoalReviews' | translate: {Default: 'No Reviews For this Goal'} }}</div>
                                                </div>
                                            </ng-container>
                                            <ng-template #showReviews>
                                                <div *ngFor="let review of childGoal.reviews; let last = last" class="review">
                                                    <ng-container *ngIf="showReview(review)">
                                                        <app-review-goal-review
                                                            [review]="review"
                                                            [settings]="settings"
                                                            [goalPlan]="selectedGoalPlan"
                                                            [goalId]="childGoal?.id"
                                                            [goalTypeId]="childGoal?.goalType?.id"
                                                            (saveReview)="saveGoalReview.emit($event)"
                                                        ></app-review-goal-review>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </div>
                                            </ng-template>
                                        </div>
                    
                                    </ng-template>
                                </mat-expansion-panel>
                            </div>

                        </ng-template>
                    </mat-expansion-panel>
                </ng-template>
              </mat-tab>

              <mat-tab *ngIf="selectedGoalPlan?.reviews !== null && selectedGoalPlan?.reviews.length > 0">
                <ng-template mat-tab-label>
                  <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Review-RatingsAndComments">
                        {{ 'Review-RatingsAndComments' | translate: {Default: 'Ratings & Comments'} }}

                        <mat-icon *ngIf="selectedGoalPlan.reviewStatus === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                        <mat-icon *ngIf="selectedGoalPlan.reviewStatus === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>
                  </div>
                </ng-template>

                <h6 
                    *ngIf="selectedGoalPlan?.reviews === null || selectedGoalPlan?.reviews.length === 0" 
                    appLocalizationPopupDirective 
                    localizationCode="Review-NoReviews"
                    class="no-reviews"
                >
                    {{ 'Review-NoReviews' | translate: {Default: 'No reviews'} }}
                </h6>

                <div *ngFor="let r of selectedGoalPlan?.reviews; let last = last">
                    <app-overall-rating-comment
                      class="review"
                      [reviewPlanId]="review?.reviewPlan?.id"
                      [review]="r"
                      [reviewerPermissions]="getReviewerGoalPlanPermissions(selectedGoalPlan?.id, r?.reviewedBy?.id)"
                      (saveReview)="goalPlanReviewSubmitted($event)"
                    ></app-overall-rating-comment>
                    <mat-divider *ngIf="!last"></mat-divider>
                </div>
  
                <!-- <app-review-goals-section [review]="review" (saveGoalReview)="saveGoalReview($event)"></app-review-goals-section> -->
              </mat-tab>
        </mat-tab-group>


    </div>
</div>
