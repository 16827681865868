export enum routes {
  BUSINESS_RULE_AUTOMATION = '/BusinessRuleAutomation',
  DASHBOARD = '/Dashboard',
  HOMEPAGE = '/Homepage',
  TYPOGRAPHY = '/typography',
  NEWSFEED = '/Newsfeed',
  INBOX = '/Inbox',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login',
  TALENT_TRACK = '/TalentTrack',
  EDIT = '/Edit',
  EMPLOYMENT_RECORDS = '/EmploymentRecords',
  FORM = '/Form',
  FORMS = '/Forms',
  FORM_EDIT = '/Form/Edit',
  CREATE_FORM = '/Form/Create',
  REPORTS = '/Reports',
  PACKET = '/Packet',
  CHECKLIST = '/Checklist/Settings',
  SITE_SETTINGS = '/SiteSettings',
  POSITIONS = '/Positions/',
  COST_CENTERS = '/CostCenters/',
  EDITOR = '/Editor',
  LISTS = '/Lists/',
  WORK_LOCATIONS = '/WorkLocations/',
  WORK_ROTATIONS = '/WorkRotations/',
  LETTERS = '/Letters/',
  LETTERS_LIST = '/List',
  LETTERS_SEND = '/Send',
  LETTERS_EDITOR = '/Editor',
  SECURITY = '/Security',
  SETUP = '/Setup',
  INTEGRATION = '/Integration',
  API = '/Api',
  ROLES = '/Roles',
  TIME_OFF = '/TimeOff',
  TIME_OFF_POLICIES_TYPES = '/PoliciesAndTypes',
  TIME_OFF_EMAILS = '/Emails',
  TIME_OFF_IN_LIEU = '/TimeOffInLieu',
  STATUTORY_HOLIDAYS = '/StatutoryHolidays',
  CONFIGURE = '/Configure',
  CURRENCY = '/Currency',
  TRANSLATIONS = '/Translations',
  BENEFITS = '/Benefits',
  COVERAGES = '/Coverages',
  PLAN_TYPES = '/PlanTypes',
  PLANS = '/Plans',
  GROUPS = '/Groups',
  ORGANIZATION = '/Organization',
  ORGANIZATION_TYPES = '/OrganizationTypes',
  EMPLOYEE_SECURITY = '/EmployeeSecurity',
  ORG_CHART = '/OrgChart',
  COUNTRY_CONFIG = '/CountryConfig',
  TABLE_FIELD_EDITOR = '/TableFieldEditor',
  ADMIN_CONTROLS = '/AdminControls',
  ASSIGN_POSITIONS = '/AssignPositions',
  GENERATE_SCHEDULE = '/GenerateSchedule',
  MY_WORK_SCHEDULE = '/MyWorkSchedule',
  SCHEDULES = '/Schedules',
  GENERATE_SHIFTS = '/GenerateShifts',
  BUS = '/Bus',
  MESSAGES = '/Messages',
  EMPLOYEE_DETAILS = '/EmployeeDetails',
  PERSONAL_DETAILS = '/PersonalDetails',
  PERFORMANCE_NO_SLASH = 'Performance',
  PERFORMANCE = '/Performance',
  GOAL_TYPES = '/GoalTypes',
  GOAL_PLANS = '/GoalPlans',
  GOALS = '/Goals',
  REVIEW_PLANS = '/ReviewPlans',
  TOIL = '/Toil',
  RECORDS = '/Records',
  TOIL_POLICIES = '/Policies',
  TOIL_EMAILS = '/Emails',
  REVIEW_RATINGS = '/ReviewRatings',
  REVIEWS = '/Reviews',
  MY_REVIEWS = '/MyReviews',
  STYLING = '/Styling',
  STYLING_COLOURS = '/StylingColours',
  COMPANY_LOGOS = '/CompanyLogos',
  MODULE_CONFIGURATION = '/ModuleConfiguration',
  CULTURE_CONFIGURATION = '/CultureConfiguration',
}
