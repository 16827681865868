<mat-card class="mat-elevation-z0" *appTableAccess="[tableReadAccessObj]">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup">
            {{ 'StatutoryHolidayGroup' | translate: {Default: "Statutory Holiday Group"} }}
        </h5>
    </mat-card-title>
    <mat-card-content>
        <button appLocalizationPopupDirective localizationCode="AssignStatutoryHolidayGroup" class="assign-btn" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAssignStatHolidayGroupDialog()" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
            <mat-icon class="material-icons-outlined" >add</mat-icon>
            {{ 'AssignStatutoryHolidayGroup' | translate: {Default: "Assign Statutory Holiday Group"} }}
        </button>

        <div *ngIf="isLoading; else loaded" class="loading-container">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>

        <ng-template #loaded>
            <div class="holiday-group">
                <ng-container *ngIf="timeOffDetail.statutoryHolidayGroup; else noGroup">
                    <h6>
                        {{timeOffDetail.statutoryHolidayGroup.name}}
                    </h6>

                    <div appLocalizationPopupDirective localizationCode="StatutoryVacationDifference" class="page-description-section statutory-vacation-difference">
                        {{ 'StatutoryVacationDifference' | translate: {Default: "Statutory Vacation Difference"} }}: <span setColor="primaryColour" class="value">{{timeOffDetail.statutoryVacationDifference}}</span>
                    </div>

                    <kendo-grid
                        [data]="gridDataResult"
                        [loading]="isLoading"
                        [pageSize]="pageSize"
                        [style.maxHeight.vh]="85" 
                        [skip]="skip"
                        [pageable]="{
                            info: true,
                            pageSizes: [5, 10, 20, 50, 100],
                            previousNext: true
                        }"
                        (pageChange)="pageChange($event)">

                        <kendo-grid-column field="name" [title]="translate.instant('Name')" width="400">
                            <ng-template kendoGridHeaderTemplate>
                                <span appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup-Name">
                                    {{ translate.instant('StatutoryHolidayGroup-Name') }}
                                </span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span class="position-name">
                                      {{dataItem.name}}
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="date">
                            <ng-template kendoGridHeaderTemplate>
                                <span appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup-StartDate">
                                    {{ translate.instant('StatutoryHolidayGroup-StartDate') }}
                                </span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        {{dataItem.startDate | date:'mediumDate' }}
                                  </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="date">
                            <ng-template kendoGridHeaderTemplate>
                                <span appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup-EndDate">
                                    {{ translate.instant('StatutoryHolidayGroup-EndDate') }}
                                </span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        {{dataItem.endDate | date:'mediumDate' }}
                                  </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="hourOverride">
                            <ng-template kendoGridHeaderTemplate>
                                <span appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup-HourOverride">
                                    {{ translate.instant('StatutoryHolidayGroup-HourOverride') }}
                                </span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        {{dataItem.hourOverride }}
                                  </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-messages 
                            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                            [pagerItems]="translate.instant('Grid-Pager-Items')"
                            [pagerOf]="translate.instant('Grid-Pager-Of')"
                        ></kendo-grid-messages>

                    </kendo-grid>
                </ng-container>
                <ng-template #noGroup>
                    <div appLocalizationPopupDirective localizationCode="NoStatutoryHolidayGroup">{{ 'NoStatutoryHolidayGroup' | translate: {Default: "No statutory holiday group assigned"} }}</div>
                </ng-template>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>