import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Holiday, HolidayGroup, HolidayGroupSubmit, HolidayGroupVerbose, HolidaySubmit, HolidayVerbose } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class StatutoryHolidaysService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getHolidayGroups(skip?: number, take?: string, filterString?: string): Observable<PagedData<HolidayGroup>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  getHolidayGroup(holidayGroupId: string): Observable<HolidayGroupVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, {
      headers: headers,
    });
  }

  postHolidayGroup(newHolidayGroup: HolidayGroupSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups`, newHolidayGroup, {
      headers: headers,
    });
  }

  putHolidayGroup(holidayGroupId: string, updatedHolidayGroup: HolidayGroupSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, updatedHolidayGroup, {
      headers: headers,
    });
  }

  deleteHolidayGroup(holidayGroupId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Groups/${holidayGroupId}`, {
      headers: headers,
    });
  }

  getHolidayDays(holidayGroupId: string, skip?: number, take?: string): Observable<PagedData<Holiday>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days?filter=StatutoryHolidayGroup = "${holidayGroupId}"`, {
      headers: headers,
      params: params
    });
  }

  getHolidayDay(holidayGroupId: string): Observable<HolidayVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayGroupId}`, {
      headers: headers,
    });
  }

  postHolidayDay(newHolidayDay: HolidaySubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days`, newHolidayDay, {
      headers: headers,
    });
  }

  putHolidayDay(holidayId: string, updateHolidayDay: HolidaySubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayId}`, updateHolidayDay, {
      headers: headers,
    });
  }

  deleteHolidayDay(holidayDayId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Timeoff/StatutoryHolidays/Days/${holidayDayId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
