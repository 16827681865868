import { Component, NgZone, OnInit } from '@angular/core';
import { ReviewPlanEmployee, ReviewPlanSubmit, ReviewPlanVerbose } from '../../models/review-plan.model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ReviewPlansService } from '../../services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { routes } from '@app/consts';
import { ReviewPlanPublishHistoryDialogComponent } from '../review-plan-publish-history-dialog/review-plan-publish-history-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';

@Component({
  selector: 'app-review-plan-editor',
  templateUrl: './review-plan-editor.component.html',
  styleUrls: ['./review-plan-editor.component.scss']
})
export class ReviewPlanEditorComponent implements OnInit {

  public view: any;
  formId: string = 'frm_6DUL8Zq9roVBT3';
  changeReasonFormId: string = 'frm_TMNy30hIXNP6I8';
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean = true;
  ratingsPristine: boolean = true;
  formData: any;
  currentFormData: any;
  reviewPlan: ReviewPlanVerbose;
  formGeneratorDialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;
  reviewPlanId: any;
  isLoading: boolean;
  refreshGoalSettings: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private reviewPlansService: ReviewPlansService,
  ){
    this.reviewPlanId = this.getIdInURL();
  }

  ngOnInit(): void {
    this.getReviewPlan();
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('reviewPlanId')
    );

    return IdInURL;
  }

  getReviewPlan(){
    this.isLoading = true;

    this.reviewPlansService.getReviewPlan(this.reviewPlanId)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.reviewPlan = res;
        this.buildFormData();
      }
    );
  }

  buildFormData() {
    this.formData = {
      id: this.reviewPlan ? this.reviewPlan.id : null,
      name: this.reviewPlan ? this.reviewPlan.name : null,
      description: this.reviewPlan ? this.reviewPlan.description : null,
      startDate: this.reviewPlan ? this.reviewPlan.startDate : null,
      endDate: this.reviewPlan ? this.reviewPlan.endDate : null,
      finalReview: this.reviewPlan ? this.reviewPlan.finalReview : null,
      setOverallRating: this.reviewPlan ? this.reviewPlan.setOverallRating : null,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: null,
    }
  }

  openPublishHistoryDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      reviewPlanId: this.reviewPlanId
    };

    const dialogRef = this.dialog.open(ReviewPlanPublishHistoryDialogComponent, dialogConfig);
  }

  formDataChange(formData) {
    this.currentFormData = formData;
  }

  formDataEmitted(formDataEmitted) {
    this.openChangeReasonDialog(formDataEmitted);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.navigateBack();
        }
      }
    );
  }

  navigateBack() {
    this.router.navigate([`${routes.PERFORMANCE}/${routes.REVIEW_PLANS}`]);
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }
      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData: ReviewPlanSubmit){
    this.overlayService.show();

    this.reviewPlansService.updateReviewPlan(formData.id, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  openConfirmPublishReviewPlanDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('PublishReviewPlanInformation')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.publishReviewPlan();
        }
      }
    );
  }

  publishReviewPlan() {
    this.overlayService.show(this.translate.instant('SendingPublishRequest'));

    this.reviewPlansService.publishReviewPlan(this.reviewPlanId)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('RequestSentSuccessfully')}`, 'clear', 'success');
        }
    );
  }
}
