import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewPlansService } from '../../services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { ReviewPlanRating, ReviewPlanRatingSubmit } from '../../models/review-plan.model';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';

@Component({
  selector: 'app-review-plan-overall-ratings',
  templateUrl: './review-plan-overall-ratings.component.html',
  styleUrls: ['./review-plan-overall-ratings.component.scss']
})
export class ReviewPlanOverallRatingsComponent implements OnInit {

  @Input() reviewPlanId: string;

  public columns: any[] = [
    { field: "rating", subField: "name", title: this.translate.instant("ReviewPlan-RatingName") },
    { field: "sortOrder", title: "Sort Order", tableId: "tfi_PrRSortOrder" },
    { field: "version", subField: "createdOn", title: this.translate.instant("ReviewPlan-CreatedOn"), type: "dateTime" },
  ];

  filterCategories: any[] = [
    { field: "rating.id", title: this.translate.instant("ReviewPlan-RatingName"), dataType: "SpecialLookup", lookupCode: 'REVIEW_RATING'},
    { field: "sortOrder", tableId: "tfi_PrRSortOrder", title: "Sort Order", type: "date", dataType: 'Date'},
    { field: "Version.CreatedOn", tableId: "", title: this.translate.instant("ReviewPlan-CreatedOn"), type: "date", dataType: 'Date'},
  ];

  sortableColumns: any[] = [
    { field: "version", sortValue: "Version.CreatedOn" },
    { field: "rating", sortValue: "Rating.Name" },
    { field: "sortOrder", sortValue: "SortOrder" },
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  sortString: string = 'SortOrder-asc';
  searchValue: string;
  searchFilterString: string;
  filterString: any;
  getRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService
  ) { }

  ngOnInit(): void {
    this.view = this.getReviewPlanRatings();
  }

  getReviewPlanRatings() {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getRequest = this.reviewPlansService.getReviewPlanRatings(this.reviewPlanId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getReviewPlanRatingVerbose(reviewPlanRating?: ReviewPlanRating) {
    if(reviewPlanRating === undefined){
      this.openReviewPlanRatingDialog();
    }
    else {
      this.openReviewPlanRatingDialog(reviewPlanRating);
    }
  }

  openReviewPlanRatingDialog(reviewPlanRating?: ReviewPlanRating) {

    let formData = {
      id: reviewPlanRating ? reviewPlanRating?.id : null,
      ratingId: reviewPlanRating ? reviewPlanRating?.rating?.id : null,
      value: reviewPlanRating ? reviewPlanRating.value : null,
      sortOrder: reviewPlanRating ? reviewPlanRating.sortOrder : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: 'frm_ikShtOgtwIVR6F',
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      reviewPlanRating ? this.updateReviewPlanRating(event) : this.createNewReviewPlanRating(event)
    });
  }

  createNewReviewPlanRating(formData: ReviewPlanRatingSubmit){
    this.overlayService.show();

    this.reviewPlansService.addReviewPlanRating(this.reviewPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getReviewPlanRatings();
        }
    );
  }

  updateReviewPlanRating(formData: ReviewPlanRatingSubmit){
    this.overlayService.show();

    this.reviewPlansService.updateReviewPlanRating(this.reviewPlanId, formData.id, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getReviewPlanRatings();
        }
    );
  }

  deleteReviewPlanRating(reviewPlanRatings: string[]) {
    const observables = reviewPlanRatings.map(selectedItem => defer(() => this.reviewPlansService.deleteReviewPlanRating(this.reviewPlanId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getReviewPlanRatings();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewPlanRatings();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Rating.Name like "${variant}")`;
        }
    });

    this.getRequest.unsubscribe();
    this.getReviewPlanRatings();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewPlanRatings();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviewPlanRatings();
  }

}
