<mat-expansion-panel class="expansion-panel mat-elevation-z0" (opened)="onPanelOpened()">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-checkbox 
                color="primary" 
                setColor="primaryColour"
                [(ngModel)]="isChecked" 
                (change)="onCheckboxChange()"
                (click)="$event.stopPropagation()" 
                (keydown)="$event.stopPropagation()" >
            </mat-checkbox>
            <h5>{{goalSetting.goal.objective}}</h5>
        </mat-panel-title>
    </mat-expansion-panel-header>
    
    <ng-template matExpansionPanelContent>
        <div class="container">
            <div class="goal-container">
                <h6>{{ 'GoalSettings-Goal' | translate: {Default: "Goal"} }}</h6>
                <app-form-generator 
                    *ngIf="!isLoadingGoal"
                    formId="frm_b0icGslD8kiybK"
                    [formData]="goalVerbose"
                    readOnly="true"
                ></app-form-generator>
            </div>
    
            <div class="goal-settings">

                <ng-container *ngIf="isLoading; else goalSettingsLoaded">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </ng-container>
                
                <ng-template #goalSettingsLoaded>

                    <div class="slider-container" *ngIf="!isLoadingGoal">
                        <app-form-generator 
                            formId="frm_sv2hp920QwMeeU"
                            [formData]="goalSettingDetails"
                            [emitFormDataTrigger]="getFormData"
                            (emitFormDataChange)="formDataChange($event)"
                            (emitFormData)="formDataEmitted($event)"
                            (emitFormStatus)="formStatusUpdated($event)"
                            (emitFormPristine)="formPristineEmitted($event)"
                        ></app-form-generator>
                    </div>
    
                    <div class="reviewers-grid" *ngIf="this.goalSettingDetails.isGloballyRated">
                        <app-data-grid-comp
                            [columns]="columns"
                            [gridDataResult]="reviewersGridDataResult"
                            [pageSize]="pageSize"
                            [skip]="skip"
                            category="Reviewers"
                            [rowSelectionSettings]="{
                                canSelectRows: true,
                                selectableMode: 'multiple',
                                selectRowBy: 'reviewer'
                            }"
                            actionButtonText="Add"
                            [isLoading]="isLoadingEmployees"
                            [clearSelectedItems]="clearSelectedItems"
                            searchable="true"
                            (emitSearchEvent)="search($event)"
                            (emitPageChangeEvent)="pageChange($event)"
                            (emitOpenDialogEvent)="showAddUserModal()"
                            (emitDeleteEvent)="deleteAllSelected($event)"
                        ></app-data-grid-comp>
                    </div>
            
                </ng-template>
            </div>
        </div>

    </ng-template>
</mat-expansion-panel>